import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { GiMoebiusTriangle } from 'react-icons/gi';
import { HiHome } from 'react-icons/hi';
import { HiOutlineArrowSmRight } from 'react-icons/hi';

interface NavButtonProps extends ButtonProps {
  icon: string;
  label: string;
  active?: boolean;
  isButton: boolean;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, active, isButton, ...buttonProps } = props;
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const getIcon = (key: string) => {
    if (key === 'contracts') {
      return GiMoebiusTriangle;
    } else if (key === 'home') {
      return HiHome;
    } else {
      return HiOutlineArrowSmRight;
    }
  };
  if (!isButton) {
    return (
      <Button
        cursor={'default'}
        variant={''}
        justifyContent={isDesktop ? 'start' : 'center'}
      >
        <HStack spacing="3">
          <Icon as={getIcon(icon)} />
          {isDesktop && <Text>{label}</Text>}
        </HStack>
      </Button>
    );
  }
  return (
    <Button
      variant={active === false ? 'darkThird' : 'dark'}
      justifyContent={isDesktop ? 'start' : 'center'}
      {...buttonProps}
    >
      <HStack spacing="3">
        <Icon as={getIcon(icon)} />
        {isDesktop && <Text>{label}</Text>}
      </HStack>
    </Button>
  );
};
