import { IconButton } from "@chakra-ui/react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { LocationElement } from "../data/location-element";
import DiamondIcon from "@mui/icons-material/Diamond";
import ExpandCircleDownTwoToneIcon from "@mui/icons-material/ExpandCircleDownTwoTone";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";

const pinkColor = "#ea558c";

interface PopularLocationsCardProps {
  data?: LocationElement[];
}

const getTableHeight = () => {
  let vhResult = 0.05290322580645161 * window.innerHeight - 10.683870967741939;
  if (vhResult > 100) {
    vhResult = 100;
  }

  return `${vhResult}vh`;
};

export const PopularLocationsCard: FunctionComponent<
  PopularLocationsCardProps
> = (props) => {
  const [tableHeight, setTableHeight] = useState(getTableHeight());

  useEffect(() => {
    window.addEventListener("resize", () => setTableHeight(getTableHeight()));
    return () =>
      window.removeEventListener("resize", () =>
        setTableHeight(getTableHeight())
      );
  }, []);

  return (
    <Card
      variant={"outlined"}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        borderRadius: "10px",
        height: "100%",
      }}
    >
      <CardContent sx={{ width: "100%" }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography variant={"h6"} color={pinkColor}>
              Popular Locations
            </Typography>
            <Typography variant={"body2"} color={pinkColor}>
              by interactions count
            </Typography>
          </Box>
          <IconButton
            aria-label="more options"
            colorScheme="darkAlpha"
            color={pinkColor}
            icon={<MoreHorizSharpIcon />}
          />
        </Box>
        <TableContainer sx={{ height: tableHeight }}>
          <Table stickyHeader aria-label="Popular locations table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={"h6"}>Location</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant={"h6"}>Volume (ETH)</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data?.map((row) => (
                <TableRow
                  key={row.label}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant={"subtitle2"}>{row.label}</Typography>
                    <Typography variant={"body2"}>{row.id}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={0.5}
                    >
                      <DiamondIcon color={"primary"} fontSize={"small"} />
                      {Math.abs(row.volume).toFixed(2)}
                      {row.volume >= 0 ? (
                        <ExpandCircleDownTwoToneIcon
                          sx={{ rotate: "180deg" }}
                          color={"success"}
                          fontSize={"small"}
                        />
                      ) : (
                        <ExpandCircleDownTwoToneIcon
                          color={"error"}
                          fontSize={"small"}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
