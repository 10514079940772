import axios, { AxiosResponse } from 'axios';
import { configuration } from '../../config/configuration';
import _ from 'lodash';

export class DashboardApiService {
  private accessToken: string;
  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }
  private buildHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    };
  }

  public async fetchApiKeys(): Promise<string[]> {
    const response = await axios.get(
      configuration.api.apiUrl + '/api-key',
      this.buildHeaders()
    );
    const result = _.map(
      response.data.contents.awsResponse.items,
      x => x.value
    );
    return result;
  }

  public async createApiKey(): Promise<AxiosResponse> {
    const response = await axios.post(
      configuration.api.apiUrl + '/api-key',
      null,
      this.buildHeaders()
    );

    return response?.data?.final?.data?.apiKeyId;
  }
}
