import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";

import DiamondIcon from "@mui/icons-material/Diamond";
const goldColor = "#f5fb8b";
const pinkColor = "#ea558c";

export interface TotalVolumeCardProps {
  label: string;
  value: string;
}

export const TotalVolumeCard: FunctionComponent<TotalVolumeCardProps> = (
  props
) => {
  const [value, setValue] = useState<string>(props.value);
  const [label, setLabel] = useState<string>(props.label);

  useEffect(() => {
    if (!props) {
      return;
    }
    setValue(props.value);
    setLabel(props.label);
  }, [props]);

  return (
    <Card
      variant={"elevation"}
      sx={{ backgroundColor: "#1e1c1f", borderRadius: "10px", height: "100%" }}
    >
      <CardContent>
        <Box display={"flex"} flexDirection={"column"} gap={4}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <IconButton
              aria-label="Copy code"
              colorScheme="pink"
              icon={<CopyIcon />}
            />
            <IconButton
              aria-label="Copy code"
              colorScheme="darkAlpha"
              color={goldColor}
              icon={<MoreHorizSharpIcon />}
            />
          </Box>
          <Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <DiamondIcon color={"secondary"} />
              <Typography variant="h5" color={"white"}>
                {value}
              </Typography>
              <ExternalLinkIcon color={pinkColor} />
            </Box>
            <Typography color={goldColor}>{label}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
