import { LoginPage } from './Pages/LoginPage/LoginPage';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { LoginCallbackPage } from './Pages/LoginCallBackPage/LoginCallbackPage';
import { PageNotFoundPage } from './Pages/PageNotFound/PageNotFound';
import { ContractDetailsPage } from './Pages/ContractDetailsPage/ContractDetailsPage';
import { CreateKeyPage } from './Pages/CreateKeyPage/CreateKeyPage';
import { EmptyLayout } from './Layouts/EmptyLayout/EmptyLayout';
import { MainLayout } from './Layouts/MainLayout/MainLayout';
import { SignupPage } from './Pages/SignupPage/SignupPage';
import { ManageRulePage } from './Pages/NewRulePage/ManageRulePage';
import { ManageContractPage } from './Pages/NewContractPage/ManageContractPage';
import { RuleListPage } from './Pages/RuleListPage/RuleListPage';
import { DashboardPage } from './Pages/DashboardPage/DashboardPage';
import { MezzanineListPage } from './Pages/MezzanineListPage/MezzanineListPage';
import { BlockchainListPage } from './Pages/BlockchainListPage/BlockchainListPage';

export const Kernel = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="home" element={<DashboardPage />} />
          <Route path="create-api-key" element={<CreateKeyPage />} />

          {/* Contract Creation */}
          <Route
            path="contracts/blockchain/new"
            element={<ManageContractPage />}
          />
          <Route
            path="contracts/mezzanine/new"
            element={<ManageContractPage />}
          />

          {/* Contract Edition */}
          <Route
            path="contracts/blockchain/edit/:contractId"
            element={<ManageContractPage />}
          />
          <Route
            path="contracts/mezzanine/edit/:contractId"
            element={<ManageContractPage />}
          />

          {/* Contract List */}
          <Route path="contracts/blockchain" element={<BlockchainListPage />} />
          <Route path="contracts/mezzanine" element={<MezzanineListPage />} />

          {/* Analytics - Not Yet Released */}
          <Route
            path="contracts/mezzanine/:id/:name/analytics"
            element={<ContractDetailsPage />}
          />
          <Route
            path="contracts/blockchain/:id/:name/analytics"
            element={<ContractDetailsPage />}
          />

          {/* Rules Creation */}
          <Route
            path="contracts/blockchain/:contractId/:contractName/rules/new"
            element={<ManageRulePage />}
          />
          <Route
            path="contracts/mezzanine/:contractId/:contractName/rules/new"
            element={<ManageRulePage />}
          />
          {/* Rules Edition */}
          <Route
            path="contracts/mezzanine/:contractId/:contractName/rules/edit/:ruleId"
            element={<ManageRulePage />}
          />
          <Route
            path="contracts/blockchain/:contractId/:contractName/rules/edit/:ruleId"
            element={<ManageRulePage />}
          />
          {/* Rules List */}
          <Route
            path="contracts/mezzanine/:contractId/:contractName/rules"
            element={<RuleListPage />}
          />
          <Route
            path="contracts/blockchain/:contractId/:contractName/rules"
            element={<RuleListPage />}
          />

          <Route index element={<DashboardPage />} />
        </Route>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="login-callback" element={<LoginCallbackPage />} />
        </Route>
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
