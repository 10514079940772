import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import {
  ManagementPage,
  ManagementPageProps,
} from '../ManagementPage/ManagementPage';
import {
  ContractEnvironment,
  ContractStatus,
  Environment,
  IContract,
} from '@mentaport/types-supplement';
import { Button } from '@chakra-ui/button';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/table';
import { ShortcutCopy } from '../ShortCutCopy/ShortCutCopy';
import { useDialog } from '../../../hooks/use-dialog';
import { shouldBeLoading } from '../../../helpers/contract-list-helper';
import { Image } from '@chakra-ui/image';
import { LocalIcons } from '../../../assets/icons/icons';
import { MessageStatus } from '../../../services/dialog';
import Icon from '@chakra-ui/icon';
import { GiMoebiusTriangle } from 'react-icons/gi';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';

export interface ContractListComponentProps {
  managementPageProps: ManagementPageProps;
  environments: ContractEnvironmentContent[];
  complexActivationLoading?: boolean;
  onEditContract: (contractId: string) => void;
  onPauseContract: (contract: IContract) => Promise<void>;
  onResumeContract: (contract: IContract) => Promise<void>;
  onActivateContract: (contract: IContract) => Promise<void>;
  onManageContractRules: (contractId: string, contractName: string) => void;
  onUsersDownLoad?: (contract: IContract) => Promise<void>;
  onShowContractAnalytics: (contractId: string, contractName: string) => void;
}
export interface ContractEnvironmentContent {
  environmentType: ContractEnvironment;
  contracts: IContract[];
}

enum ContractActions {
  Activate = 'activate',
  Pause = 'pause',
  Resume = 'resume',
  Download = 'download',
}

export const ContractListComponent: FunctionComponent<
  ContractListComponentProps
> = props => {
  const [asyncActionIsRunning, setAsyncActionIsRunning] =
    useState<boolean>(false);
  const [focusedContractId, setFocusOnContractId] = useState<string>('');
  const [activeAction, setActiveAction] = useState<
    ContractActions | undefined
  >();
  const dialog = useDialog();
  const [complexActivationLoading, setComplexActivationLoading] = useState(
    props.complexActivationLoading
  );

  useEffect(() => {
    setComplexActivationLoading(props.complexActivationLoading);
  }, [props.complexActivationLoading]);

  const renderBlockchainIcon = (blockchain: string) => {
    const iconMap: any = {
      ethereum: LocalIcons.EthereumLogo,
      polygon: LocalIcons.PolygonLogo,
      sui: LocalIcons.SuiLogo,
    };
    return (
      <Image
        margin="0 auto"
        borderRadius={100}
        width={'1.5rem'}
        height={'1.5rem'}
        src={iconMap[blockchain]}
        alt={blockchain}
      />
    );
  };
  const renderEnvironmentPanel = (environment: ContractEnvironmentContent) => {
    if (environment.contracts.length === 0) {
      return (
        <Flex
          gap={5}
          borderRadius={5}
          alignItems={'center'}
          justifyContent={'center'}
          height={'10rem'}
          ml={4}
          mr={4}
        >
          <Icon as={GiMoebiusTriangle} boxSize={'40px'} />
          <Text>No contracts of this kind exist yet.</Text>
        </Flex>
      );
    }
    return (
      <TableContainer>
        <Table width={'100%'}>
          <Thead>
            <Tr>
              <Th width={16}>ID</Th>
              {props.managementPageProps.isBlockchain && <Th>Blochchain</Th>}
              <Th>Name</Th>
              <Th width={20}>Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {environment.contracts.map((contract: IContract) => (
              <Tr
                key={contract.contractId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <Td align="left" width={16}>
                  <ShortcutCopy id={contract.contractId} />
                </Td>

                {props.managementPageProps.isBlockchain && (
                  <Td align="center" width={20}>
                    {renderBlockchainIcon(contract.blockchain)}
                  </Td>
                )}
                <Td>
                  <strong>{contract.name}</strong>
                </Td>
                <Td width={20} align="left">
                  {renderStatus(contract)}
                </Td>
                <Td className="rowTools" align="left">
                  {renderLifecycleContractTools(contract)}
                  <Button
                    margin={2}
                    onClick={() => props.onEditContract(contract.contractId)}
                  >
                    Edit
                  </Button>
                  <Button
                    margin={2}
                    onClick={() => {
                      props.onManageContractRules(
                        contract.contractId,
                        contract.name
                      );
                    }}
                  >
                    Manage rules
                  </Button>
                  {props.onUsersDownLoad && (
                    <Button
                      isDisabled={contract.status === ContractStatus.NonActive}
                      isLoading={shouldBeLoading(
                        contract.contractId,
                        focusedContractId,
                        asyncActionIsRunning,
                        activeAction === ContractActions.Download
                      )}
                      margin={2}
                      onClick={() => {
                        setActiveAction(ContractActions.Download);
                        handleAsyncAction(contract, () =>
                          props.onUsersDownLoad?.(contract)
                        );
                      }}
                    >
                      Download
                    </Button>
                  )}

                  <Button
                    disabled={true}
                    onClick={() => {
                      props.onShowContractAnalytics(
                        contract.contractId,
                        contract.name
                      );
                    }}
                  >
                    Analytics
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  const handleAsyncAction = async (
    contract: IContract,
    action?: (contract: IContract) => Promise<void> | undefined
  ) => {
    if (!action) {
      return;
    }
    try {
      setAsyncActionIsRunning(true);
      setFocusOnContractId(contract.contractId);
      await action(contract);
    } catch (error: any) {
      dialog.notify(
        MessageStatus.Error,
        `${
          contract.environment === ContractEnvironment.Mainnet
            ? 'Mezzanine'
            : 'Blockchain'
        } Contracts`,
        error?.data.message ?? 'There was an error during thee process'
      );
    } finally {
      setAsyncActionIsRunning(false);
    }
  };

  const renderLifecycleContractTools = (contract: IContract) => {
    return (
      <>
        {contract.status === ContractStatus.NonActive && (
          <Button
            isLoading={
              shouldBeLoading(
                contract.contractId,
                focusedContractId,
                asyncActionIsRunning,
                activeAction === ContractActions.Activate
              ) || complexActivationLoading
            }
            key="btn-pause"
            width={10}
            cursor={'pointer'}
            variant="orange"
            aria-label="Activate"
            onClick={() => {
              setActiveAction(ContractActions.Activate);
              handleAsyncAction(contract, () =>
                props.onActivateContract(contract)
              );
            }}
          >
            <Image
              margin="0 auto"
              borderRadius={0}
              objectFit={'cover'}
              width={'1rem'}
              height={'1rem'}
              src={LocalIcons.Launch}
              alt={'Pause'}
            />
          </Button>
        )}
        {contract.status === ContractStatus.Active && (
          <Button
            isLoading={shouldBeLoading(
              contract.contractId,
              focusedContractId,
              asyncActionIsRunning,
              activeAction === ContractActions.Pause
            )}
            key="btn-pause"
            width={10}
            cursor={'pointer'}
            onClick={() => {
              setActiveAction(ContractActions.Pause);
              handleAsyncAction(contract, () => {
                return props.onPauseContract(contract);
              });
            }}
          >
            <Image
              margin="0 auto"
              borderRadius={0}
              width={'1rem'}
              height={'1rem'}
              src={LocalIcons.Pause}
              alt={'Pause'}
            />
          </Button>
        )}
        {contract.status === ContractStatus.Paused && (
          <Button
            isLoading={shouldBeLoading(
              contract.contractId,
              focusedContractId,
              asyncActionIsRunning,
              activeAction === ContractActions.Resume
            )}
            key="btn-resume"
            width={10}
            cursor={'pointer'}
            onClick={() => {
              setActiveAction(ContractActions.Resume);
              handleAsyncAction(contract, () => {
                return props.onResumeContract(contract);
              });
            }}
          >
            <Image
              margin="0 auto"
              borderRadius={0}
              width={'1rem'}
              height={'1rem'}
              src={LocalIcons.Play}
              alt={'Resume'}
            />
          </Button>
        )}
      </>
    );
  };

  const renderStatus = (contract: IContract) => {
    let variantName = '';
    let label = '';
    if (contract.status === ContractStatus.NonActive) {
      label = 'Inactive';
      variantName = 'black';
    } else if (contract.status === ContractStatus.Active) {
      label = 'Active';
      variantName = 'mint';
    } else if (contract.status === ContractStatus.Paused) {
      label = 'Paused';
      variantName = 'orange';
    } else if (contract.status === ContractStatus.Pending) {
      label = 'Pending';
      variantName = 'yellow';
    }
    return (
      <Button key="status" width={100} cursor={'default'} variant={variantName}>
        {label}
      </Button>
    );
  };
  return (
    <Flex flexDirection="column" height={'100%'} width="100%">
      <ManagementPage
        title={props.managementPageProps.title}
        buttons={props.managementPageProps.buttons}
        path={props.managementPageProps.path}
        isBlockchain={props.managementPageProps.isBlockchain}
        hideBackButton={props.managementPageProps.hideBackButton}
        isLoading={props.managementPageProps.isLoading}
        placeholder={props.managementPageProps.placeholder}
        breadcrumbs={props.managementPageProps.breadcrumbs}
        previousLocation={props.managementPageProps.previousLocation}
      >
        <Tabs colorScheme="black" overflowY={'scroll'}>
          <TabList>
            {props.environments.map(environment => {
              return (
                <Tab fontSize={'large'}>
                  {environment?.environmentType.toString().toUpperCase()}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {props.environments.map(environment => {
              return (
                <TabPanel>
                  <Box key={environment.environmentType}>
                    {renderEnvironmentPanel(environment)}
                  </Box>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </ManagementPage>
    </Flex>
  );
};
