import {
  BarHoverOptions,
  BarOptions,
  Chart,
  ChartType,
  CoreChartOptions,
  ElementChartOptions,
  LinearScaleOptions,
  LineHoverOptions,
  LineOptions,
  LogarithmicScale,
  PluginChartOptions,
  PointHoverOptions,
  PointOptions,
  ScaleChartOptions,
  ScriptableAndArrayOptions,
  ScriptableContext,
  Tick,
  TooltipOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  AxisStyle,
  ChartClip,
  DataLabelPosition,
  DataLabelStyle,
  EmptyState,
} from '../data/base-chart-interface';
Chart.register(LogarithmicScale, ChartDataLabels);

export class ChartOptionBuilder<T extends ChartType> {
  constructor(axisStyle: AxisStyle[]) {
    this.scalesChartOption = {
      scales: axisStyle
        ? this.getScales(axisStyle)
        : { x: { ticks: { font: {} }, grid: {} }, y: { ticks: {}, grid: {} } },
    };
    this.setAxisStyle(axisStyle);
    this.coreChartOption = { layout: {} } as any;
    this.elementChartOption = {
      elements: { line: {}, point: {}, bar: {} } as any,
    };
    this.pluginChartOption = {
      plugins: {
        datalabels: {},
        legend: { labels: {} },
        title: {},
        tooltip: { callbacks: {} },
      } as any,
    };
  }

  private coreChartOption: CoreChartOptions<T>;
  private elementChartOption: ElementChartOptions<T>;
  private scalesChartOption: ScaleChartOptions<T>;
  private pluginChartOption: PluginChartOptions<T>;

  getLineElementChartOption = (): ScriptableAndArrayOptions<
    LineOptions & LineHoverOptions,
    ScriptableContext<ChartType>
  > => {
    return this.elementChartOption.elements.line;
  };

  getPointElementChartOption = (): ScriptableAndArrayOptions<
    PointOptions & PointHoverOptions,
    ScriptableContext<ChartType>
  > => {
    return this.elementChartOption.elements.point;
  };

  getBarElementChartOption = (): ScriptableAndArrayOptions<
    BarOptions & BarHoverOptions,
    ScriptableContext<ChartType>
  > => {
    return this.elementChartOption.elements.bar;
  };

  private getScales = (axisStyle: AxisStyle[]) => {
    const content = axisStyle?.map(axis => {
      return `"${axis.id}": { "pointLabels":{"font":{}}, "ticks": { "font":{ }}, "grid": {} }`;
    });
    return JSON.parse(`{${content?.join(',')}}`) as any;
  };

  serPadding(value?: number) {
    if (value) {
      this.coreChartOption.layout.padding = value;
    }
    return this;
  }

  setLineElement = (
    line: ScriptableAndArrayOptions<
      LineOptions & LineHoverOptions,
      ScriptableContext<ChartType>
    >
  ) => {
    this.elementChartOption.elements.line = line;
  };

  setBarElement = (
    bar: ScriptableAndArrayOptions<
      BarOptions & BarHoverOptions,
      ScriptableContext<ChartType>
    >
  ) => {
    this.elementChartOption.elements.bar = bar;
  };

  setPointElement = (
    point: ScriptableAndArrayOptions<
      PointOptions & PointHoverOptions,
      ScriptableContext<ChartType>
    >
  ) => {
    this.elementChartOption.elements.point = point;
  };

  setResponsive = (value: boolean) => {
    this.coreChartOption.responsive = value;
    return this;
  };

  maintainAspectRatio = (value?: boolean) => {
    if (value) {
      this.coreChartOption.maintainAspectRatio = value;
    }
    return this;
  };

  setAspectRatio = (value?: number) => {
    if (value) {
      this.coreChartOption.aspectRatio = value;
    }
    return this;
  };

  setLineTension = (value: number) => {
    if (this.elementChartOption.elements) {
      this.elementChartOption.elements.line.tension = value;
    }
    return this;
  };

  setBarBorderRadius = (value: number) => {
    this.elementChartOption.elements.bar.borderRadius = value;
    return this;
  };

  setBarBorderSkipped = (value: string | false) => {
    (this.elementChartOption.elements.bar.borderSkipped as any) = value;
    return this;
  };

  setLineBorderWith = (value: number) => {
    this.elementChartOption.elements.line.borderWidth = value;
    return this;
  };

  setLinePointRadius = (value: number) => {
    this.elementChartOption.elements.point.radius = value;
    return this;
  };

  setLinePointBorderWidth = (value: number) => {
    this.elementChartOption.elements.point.borderWidth = value;
    return this;
  };

  setDataLabelStyle = (dataLabelStyle: DataLabelStyle) => {
    if (dataLabelStyle) {
      this.pluginChartOption.plugins.datalabels = {
        display: dataLabelStyle.display ?? false,
        color: dataLabelStyle.color ?? '#ffffff',
        align: dataLabelStyle.align ?? DataLabelPosition.End,
        anchor: dataLabelStyle.anchor ?? DataLabelPosition.End,
        padding: dataLabelStyle.padding ?? 0,
        font: { size: dataLabelStyle.fontSize ?? 12 },
        formatter: (value, context) =>
          dataLabelStyle.onFormatter?.(value, context),
      };
    }
    return this;
  };

  setAxisStyle = (axisStyle: AxisStyle[]) => {
    if (axisStyle) {
      axisStyle.map(axis =>
        this.showScaleForAxis(axis.id, axis.showScales ?? false)
          .showScaleGridForAxis(axis.id, axis.showScalesGrid ?? false)
          .drawBorderGrid(axis.id, axis.showScalesGridBorder ?? false)
          .setScaleGridColor(axis.id, axis.gridColor)
          .setScalePositionForAxis(axis.id, axis.position)
          .setScaleGridBorderColorForAxis(axis.id, axis.gridBorderColor)
          .setScaleTicksColor(axis.id, axis.tickColor)
          .setScaleMax(axis.id, axis.max)
          .setScaleMin(axis.id, axis.min)
          .setScaleGrace(axis.id, axis.grace)
          .setScaleTicksFontSize(axis.id, axis.tickFontSize)
          .setScaleTicksFontWeight(axis.id, axis.tickFontWeight)
          .setLogarithmicScaleInAxis(axis.id, axis.isLogarithmicScale ?? false)
          .setScaleTickBackGroundColor(axis.id, axis.tickBackgroundColor)
          .setScaleTickStepSize(axis.id, axis.tickStepSize)
      );
      return this;
    }
    this.showScaleForAxis('x', false);
    this.showScaleForAxis('y', false);
    return this;
  };

  setLogarithmicScaleInAxis = (scaleId: string, value: boolean) => {
    if (value) {
      this.scalesChartOption.scales[scaleId].type = 'logarithmic';
    }
    return this;
  };

  showPointLabels = (scaleId: string, value: boolean) => {
    (this.scalesChartOption.scales[scaleId] as any).pointLabels.display = value;
    return this;
  };

  setPointLabelsColor = (scaleId: string, value?: string) => {
    if (value) {
      (this.scalesChartOption.scales[scaleId] as any).pointLabels.color = value;
    }
    return this;
  };

  setPointLabelsFontSize = (scaleId: string, value?: number) => {
    if (value) {
      (this.scalesChartOption.scales[scaleId] as any).pointLabels.font.size =
        value;
    }
    return this;
  };

  setPointLabelsFontWeight = (scaleId: string, value?: number) => {
    if (value) {
      (this.scalesChartOption.scales[scaleId] as any).pointLabels.font.weight =
        value;
    }
    return this;
  };

  setScaleGrace = (scaleId: string, value?: string) => {
    if (value) {
      (this.scalesChartOption.scales[scaleId] as LinearScaleOptions).grace =
        value;
    }
    return this;
  };

  setScaleMax = (scaleId: string, value?: number) => {
    if (value) {
      this.scalesChartOption.scales[scaleId].max = value;
    }
    return this;
  };

  setScaleMin = (scaleId: string, value?: number) => {
    if (value) {
      this.scalesChartOption.scales[scaleId].min = value;
    }
    return this;
  };

  showScaleForAxis = (scaleId: string, value: boolean) => {
    this.scalesChartOption.scales[scaleId].display = value;
    return this;
  };

  setScaleTicksColor = (scaleId: string, color?: string) => {
    if (color) {
      this.scalesChartOption.scales[scaleId].ticks.color = color;
    }
    return this;
  };
  setScaleTickBackGroundColor = (scaleId: string, color?: string) => {
    if (color) {
      this.scalesChartOption.scales[scaleId].ticks.backdropColor = color;
    }
    return this;
  };

  setScaleTickStepSize = (scaleId: string, value?: number) => {
    if (value) {
      (this.scalesChartOption.scales[scaleId].ticks as any).stepSize = value;
    }
    return this;
  };

  setScaleTickCallback = (
    scaleId: string,
    callback: (
      tickValue: string | number,
      index: number,
      ticks: Tick[]
    ) => string | string[] | number | number[] | null | undefined
  ) => {
    if (callback) {
      this.scalesChartOption.scales[scaleId].ticks.callback = (
        tickValue,
        index,
        ticks
      ) => callback(tickValue, index, ticks);
    }
    return this;
  };

  setScaleTicksFontSize = (scaleId: string, size?: number) => {
    if (size) {
      (this.scalesChartOption.scales[scaleId].ticks.font as any).size = size;
    }
    return this;
  };

  setScaleTicksFontWeight = (scaleId: string, weight?: number) => {
    if (weight) {
      (this.scalesChartOption.scales[scaleId].ticks.font as any).weight =
        weight;
    }
    return this;
  };

  setScaleGridColor = (scaleId: string, value?: string) => {
    if (value) {
      this.scalesChartOption.scales[scaleId].grid.color = value;
    }
    return this;
  };

  drawBorderGrid = (scaleId: string, value: boolean) => {
    (this.scalesChartOption.scales[scaleId].grid as any).drawBorder = value;
    return this;
  };

  setScalePositionForAxis = (scaleId: string, value?: string) => {
    if (value) {
      (this.scalesChartOption.scales[scaleId] as any).position = value;
    }
    return this;
  };

  showScaleGridForAxis = (scaleId: string, value: boolean) => {
    this.scalesChartOption.scales[scaleId].grid.display = value;
    return this;
  };

  setScaleGridBorderColorForAxis = (scaleId: string, value?: string) => {
    if (value) {
      (this.scalesChartOption.scales[scaleId].grid as any).borderColor = value;
    }
    return this;
  };

  setStakedScaleAxis = (scaleId: string, value: boolean) => {
    (this.scalesChartOption.scales[scaleId] as any).stacked = value;
    return this;
  };

  setScaleAxisBarThickness = (scaleId: string, value: number) => {
    (this.scalesChartOption.scales[scaleId] as any).barPercentage = value;
    (this.scalesChartOption.scales[scaleId] as any).categoryPercentage = value;
    return this;
  };

  showLegend = (value: boolean) => {
    this.pluginChartOption.plugins.legend.display = value;
    return this;
  };

  setLabelsColor = (value: string) => {
    this.pluginChartOption.plugins.legend.labels.color = value;
    return this;
  };

  showTitle = (value: boolean) => {
    this.pluginChartOption.plugins.title.display = value;
    return this;
  };

  setTitle = (value: string) => {
    this.pluginChartOption.plugins.title.text = value;
    return this;
  };

  setTitleColor = (value: string) => {
    this.pluginChartOption.plugins.title.color = value;
    return this;
  };

  showZeroForEmptyLabel = (value?: boolean) => {
    if (!value) {
      return this;
    }
    this.pluginChartOption.plugins.tooltip.callbacks.label = (context: any) => {
      let label = context.dataset.label || '';

      if (label) {
        label += ': ';
      }

      if (context.parsed.y !== null && label !== `${EmptyState}: `) {
        label += context.parsed.y;
      } else {
        label += 0;
      }
      return label;
    };
    return this;
  };

  setChartClip(value: false | number | ChartClip) {
    (this.coreChartOption as any).clip = value;
    return this;
  }

  showCustomTooltip(toolTipConfig: TooltipOptions | undefined) {
    if (!toolTipConfig) return this;

    (this.pluginChartOption.plugins.tooltip as any) = toolTipConfig;

    return this;
  }

  build = () => {
    return {
      elements: this.elementChartOption.elements,
      responsive: this.coreChartOption.responsive,
      scales: this.scalesChartOption.scales,
      maintainAspectRatio: this.coreChartOption.maintainAspectRatio,
      layout: this.coreChartOption.layout,
      aspectRatio: this.coreChartOption.aspectRatio,
      plugins: this.pluginChartOption.plugins,
      clip: (this.coreChartOption as any).clip,
    };
  };
}

export const chartOptionBuilder = (axisStyle: AxisStyle[]) =>
  new ChartOptionBuilder(axisStyle);
