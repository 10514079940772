import { FunctionComponent, useEffect, useState } from 'react';
import { IPlace } from '@mentaport/types-common';
import { Flex, Input, Select } from '@chakra-ui/react';
import {
  getUsaCitiesFromState,
  getUsaStates,
} from '../../../../../data/usa-states';

export interface CityElementProps {
  place: IPlace;
  onChange: (value: IPlace) => void;
}

export const CityElement: FunctionComponent<CityElementProps> = props => {
  const [place, setPlace] = useState<IPlace>({
    countryCode: 'US',
    city: props.place.city,
    state: props.place.city ? props.place.state : '',
  });

  useEffect(() => {
    props.onChange(place);
  }, [place]);

  const getStateOptions = () => {
    const states = getUsaStates();
    if (!states) {
      return <></>;
    }
    return states.map((_state, index) => {
      if (!_state) {
        return <></>;
      }
      return (
        <option selected={_state === place.state} key={index} value={_state}>
          {_state}
        </option>
      );
    });
  };

  const getCityOptions = () => {
    if (!place.state) {
      return <></>;
    }
    const cities = getUsaCitiesFromState(place.state);
    if (!cities) {
      return <></>;
    }
    return cities.map((city: any, index) => {
      if (!city) {
        return <></>;
      }
      return (
        <option selected={city === place.city} key={index} value={city}>
          {city}
        </option>
      );
    });
  };

  return (
    <Flex direction={'column'} gap={3}>
      <Flex direction={'column'} gap={1}>
        <strong>USA states</strong>
        <Select
          value={place.state}
          bgColor={'white'}
          placeholder="State Name"
          onChange={event => {
            setPlace({
              ...place,
              state: event.currentTarget.value,
            });
          }}
        >
          {getStateOptions()}
        </Select>
        <strong>USA City</strong>
        <Select
          isDisabled={!place.state || place.state === ''}
          value={place.city}
          bgColor={'white'}
          placeholder="City Name"
          onChange={event => {
            setPlace({
              ...place,
              city: event.currentTarget.value,
            });
          }}
        >
          {getCityOptions()}
        </Select>
      </Flex>
    </Flex>
  );
};
