import { extendTheme } from '@chakra-ui/react';

const brandColors = {
  brand_pink: {
    50: '#ffecf3',
    100: '#fec5db',
    200: '#fda9ca',
    300: '#fc82b1',
    400: '#fc69a2',
    500: '#fb448b',
  },
  brand_yellow: {
    50: '#fefff1',
    100: '#fbfed5',
    200: '#f9fdc0',
    300: '#f7fca3',
    400: '#f5fc91',
    500: '#f3fb76',
  },
  brand_mint: {
    50: '#f2fffb',
    100: '#d5fff2',
    200: '#c1ffec',
    300: '#a5ffe4',
    400: '#93ffde',
    500: '#89e8ab',
  },
  brand_purple: {
    50: '#F6F6FF',
    100: '#E2E3FE',
    200: '#D3D6FE',
    300: '#BFC3FD',
    400: '#B3B8FD',
    500: '#A0A6FC',
  },
  brand_blue: {
    50: '#F0F6FF',
    100: '#D1E4FE',
    200: '#BAD7FE',
    300: '#9BC4FD',
    400: '#87B9FD',
    500: '#69A7FC',
  },
  brand_navy: {
    50: '#E8EBF1',
    100: '#B9C1D5',
    200: '#97A3C0',
    300: '#6878A3',
    400: '#4A5E91',
    500: '#1D3676',
  },
  brand_black: {
    50: '#ebebeb',
    100: '#bfbfbf',
    200: '#a1a1a1',
    300: '#767676',
    400: '#5b5b5b',
    500: '#121213',
  },
};

export const theme = extendTheme({
  shadows: ['none', '0px 2px 4px rgba(0, 0, 0, 0.1)'],
  colors: {
    brand_pink: {
      50: '#ffecf3',
      100: '#fec5db',
      200: '#fda9ca',
      300: '#fc82b1',
      400: '#fc69a2',
      500: '#fb448b',
    },
    brand_yellow: {
      50: '#fefff1',
      100: '#fbfed5',
      200: '#f9fdc0',
      300: '#f7fca3',
      400: '#f5fc91',
      500: '#f3fb76',
    },
    brand_mint: {
      50: '#f2fffb',
      100: '#d5fff2',
      200: '#c1ffec',
      300: '#a5ffe4',
      400: '#93ffde',
      500: '#78ffd6',
    },
    brand_purple: {
      50: '#F6F6FF',
      100: '#E2E3FE',
      200: '#D3D6FE',
      300: '#BFC3FD',
      400: '#B3B8FD',
      500: '#A0A6FC',
    },
    brand_blue: {
      50: '#F0F6FF',
      100: '#D1E4FE',
      200: '#BAD7FE',
      300: '#9BC4FD',
      400: '#87B9FD',
      500: '#69A7FC',
    },
    brand_navy: {
      50: '#E8EBF1',
      100: '#B9C1D5',
      200: '#97A3C0',
      300: '#6878A3',
      400: '#4A5E91',
      500: '#1D3676',
    },
    brand_black: {
      50: '#ebebeb',
      100: '#bfbfbf',
      200: '#a1a1a1',
      300: '#767676',
      400: '#5b5b5b',
      500: '#121213',
    },
  },
  components: {
    Button: {
      variants: {
        pink: {
          bg: brandColors.brand_pink[500],
          color: 'white',
          _hover: {
            bg: brandColors.brand_black[500],
            _disabled: {
              bg: brandColors.brand_black[400],
            },
          },
        },
        pinkSecondary: {
          bg: brandColors.brand_pink[400],
          color: brandColors.brand_black[500],
          _hover: {
             bg: brandColors.brand_black[400],
            _disabled: {
              bg: brandColors.brand_pink[100],
            },
          },
        },
        blue: {
          bg: brandColors.brand_blue[500],
          color: 'white',
          _hover: {
            _disabled: {
              bg: brandColors.brand_blue[400],
            },
          },
        },
        blueSecondary: {
          bg: brandColors.brand_blue[100],
          color: brandColors.brand_blue[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_blue[100],
            },
          },
        },
        purple: {
          bg: brandColors.brand_purple[500],
          color: 'white',
          _hover: {
            _disabled: {
              bg: brandColors.brand_purple[400],
            },
          },
        },
        purpleSecondary: {
          bg: brandColors.brand_purple[100],
          color: brandColors.brand_purple[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_purple[100],
            },
          },
        },
        navy: {
          bg: brandColors.brand_navy[500],
          color: 'white',
          _hover: {
            _disabled: {
              bg: brandColors.brand_navy[400],
            },
          },
        },
        navySecondary: {
          bg: brandColors.brand_navy[100],
          color: brandColors.brand_navy[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_navy[100],
            },
          },
        },
        yellow: {
          bg: brandColors.brand_yellow[500],
          color: brandColors.brand_black[500],
          _hover: {
            bg: brandColors.brand_black[500],
            color:"white",
            _disabled: {
              bg: brandColors.brand_yellow[500],
            },
          },
        },
        yellowSecondary: {
          bg: brandColors.brand_yellow[100],
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_yellow[100],
            },
          },
        },
        mint: {
          bg: brandColors.brand_mint[500],
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_mint[500],
            },
          },
        },
        mintSecondary: {
          bg: brandColors.brand_mint[100],
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_mint[100],
            },
          },
        },
        dark: {
          bg: brandColors.brand_black[500],
          color: 'white',
          _hover: {
            bg: brandColors.brand_yellow[500],
            color: 'black',
            _disabled: {
              bg: brandColors.brand_yellow[500],
            },
          },
        },
        darkSecondary: {
          bg: brandColors.brand_black[100],
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_black[100],
            },
          },
        },
        darkThird: {
          // bg: brandColors.brand_black[100],
          // color: brandColors.brand_black[500],
          _hover: {
            bg: brandColors.brand_yellow[500],
            _disabled: {
              bg: brandColors.brand_black[100],
            },
          },
        },
        orange: {
          bg: 'orange',
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: 'orange.100',
            },
          },
        },
      },
    },
  },
});
