import { Input } from '@chakra-ui/input';
import { Box, Text } from '@chakra-ui/layout';
import { FunctionComponent, useEffect, useState } from 'react';

interface MentaportInputProps {
  isDisabled: boolean;
  value: string | number;
  title: string;
  subtitle?: string;
  onChange: (value: any) => void;
  inputType?: 'text' | 'number';
  maxNumericValue?: number;
  minNumericValue?: number;
}

export const MentaportInput: FunctionComponent<MentaportInputProps> = props => {
  const [isDisabled, setIsDisable] = useState(props.isDisabled);
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setIsDisable(props.isDisabled);
    setValue(props.value);
  }, [props.isDisabled, props.value]);
  return (
    <Box className="NewContractPageInput" w={'100%'}>
     
      {props.subtitle ?
        <Text className="NewContractPageInputTitle1">
        {props.title}
         <Text as="span" className="NewContractPageInputTitleSmall"> {props.subtitle}</Text>
        </Text>
     :
       <Text className="NewContractPageInputTitle" >
        {props.title} 
      </Text>
      }
      <Input
        type={props.inputType ?? 'text'}
        max={props.maxNumericValue}
        min={props.minNumericValue}
        isDisabled={isDisabled}
        h={'3.375rem'}
        borderRadius={'0.625rem'}
        borderColor={'black'}
        value={value}
        onChange={event =>
          props.onChange(
            props.inputType === 'number'
              ? event.currentTarget.valueAsNumber
              : event.currentTarget.value
          )
        }
      />
    </Box>
  );
};
