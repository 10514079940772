import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import './ManagementPage.scss';
import { useApiKeys } from '../../../hooks/use-api-key';

export interface ManagementPageProps {
  title: string;
  path: string;
  isBlockchain?: boolean;
  isLoading: boolean;
  buttons?: ReactNode[];
  placeholder?: ReactNode;
  pageParams?: string[];
  breadcrumbs?: UserNavigationRoute[];
  hideBackButton?: boolean;
  previousLocation: string;
}

export interface UserNavigationRoute {
  label: string;
  url?: string;
  current?: boolean;
  bold?: boolean;
}

export const ManagementPage: FunctionComponent<
  PropsWithChildren<ManagementPageProps>
> = props => {
  const navigate = useNavigate();
  const [apiKeys, apiKeysIsLoading] = useApiKeys();
  const getBreadcrumbItems = () => {
    if (!props.breadcrumbs) {
      return;
    }
    const entries = props.breadcrumbs.map((route, index) => {
      return (
        <BreadcrumbItem key={index} isCurrentPage={route.url === undefined}>
          {route.url === undefined && <label>{route.label}</label>}
          {route.url && (
            <BreadcrumbLink href={`/${route.url}`}>
              {route.label}
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      );
    });
    return (
      <Breadcrumb color={'gray'} fontWeight="medium">
        {entries}
      </Breadcrumb>
    );
  };

  const getContent = () => {
    return !apiKeys || !apiKeys.length ? (
      <Box
        height={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={5}
      >
        <strong>Looks like you have not created an API Key</strong>
        <Button variant={'pink'} onClick={() => navigate('/create-api-key')}>
          Let's Go Make One
        </Button>
      </Box>
    ) : !props.isLoading ? (
      props.children
    ) : (
      props.placeholder
    );
  };

  return (
    <Flex w={'100%'} className="ManagementPage" direction={'column'}>
      <Flex
        className="ManagementPageHeader"
        direction={'row'}
        paddingTop={2}
        paddingBottom={2}
        borderBottom="1px solid #f2f2f2"
        align={'center'}
      >
        <Flex
          width={'100%'}
          direction={'row'}
          gap={2}
          marginLeft={4}
          justifyItems={'center'}
          alignContent={'center'}
        >
          <Text className="ManagementPageTitle">
            <strong>{props.title?.toUpperCase()}</strong>
          </Text>
          {getBreadcrumbItems()}
        </Flex>

        <Flex
          direction={'row'}
          width={'100%'}
          gap={2}
          justifyContent={'end'}
          paddingRight={4}
        >
          {props.buttons}
          {false && (
            <Button
              variant={'pink'}
              size="sm"
              onClick={() => {
                navigate(`/${props.previousLocation}`);
              }}
            >
              Go Back
            </Button>
          )}
        </Flex>
      </Flex>

      {!apiKeysIsLoading ? getContent() : props.placeholder}
    </Flex>
  );
};
