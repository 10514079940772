import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { Navbar } from "../../components/navigation/Navbar";

export const MainLayout = () => {
  return (
    <Box
      as="section"
      height="100vh"
      overflowY="hidden"
      display="flex"
      flexDirection="row"
    >
      <Navbar />
      <Box className="page-container">
        <Outlet />
      </Box>
    </Box>
  );
};
