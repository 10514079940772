import { withAuthProtection } from '../../services/protect-route-element';
import { Button } from '@chakra-ui/react';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import './MezzanineListPage.scss';
import { trackEvent, useAnalytics } from '../../hooks/use-analytics';
import { useNavigate } from 'react-router-dom';
import { useMentaport } from '../../hooks/use-mentaport';
import {
  IContract,
  ContractStatus,
  ContractEnvironment,
} from '@mentaport/types-supplement';
import { IResults } from '@mentaport/types-common';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { getPlaceHolder } from '../../helpers/list-helper';
import { Routes } from '../../data/routes';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';
import _ from 'lodash';
import { ContractListComponent } from '../../components/commons/ContractListComponent/ContractListComponent';

const Page = () => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const mentaport = useMentaport();
  const dialog = useDialog();

  const [contractsResponse, contractsResponseLoading, setContracts] =
    useDataFetcher<IResults<IContract[]>>({
      serviceCall: () => {
        return mentaport?.fetchContracts();
      },
      dependencies: [mentaport],
      conditionForExecution: mentaport !== undefined,
      onError(error) {
        dialog.notify(
          MessageStatus.Error,
          'Mezzanine Contracts',
          'There was an error fetching the mezzanine contracts.'
        );
        console.error(error);
      },
    });

  const handleCreateContract = async () => {
    if (analytics) {
      trackEvent('Navigate to Create a Mezzanine Contract', analytics);
    }
    navigate('/contracts/mezzanine/new');
  };

  const handleActivate = async (contract: IContract) => {
    try {
      if (analytics) {
        trackEvent('Activate Mezzanine Contract Users', analytics);
      }
      const result = await mentaport?.activateMezzanineContract(contract);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Active);
        dialog.notify(
          MessageStatus.Success,
          'Mezzanine Contracts',
          'The contract has been activated'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Mezzanine Contracts',
          'There was an issue activating the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Mezzanine Contracts',
        'There was an error activating the contract'
      );
    }
  };

  const handleDownload = async (contract: IContract) => {
    if (!mentaport || !contract) {
      return;
    }
    try {
      if (analytics) {
        trackEvent('Download Mezzanine Contract Users', analytics);
      }
      const csvConfig = mkConfig({ useKeysAsHeaders: true });
      const users = await mentaport.fetchMezzanineUsers(contract);
      if (!users.data || users.data.length <= 0) {
        dialog.notify(
          MessageStatus.Info,
          'Mezzanine Contracts',
          'No users have connected to this contract'
        );

        return;
      }
      const csv = generateCsv(csvConfig)(users.data as any[]);
      download(csvConfig)(csv);
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Mezzanine Contracts',
        'There was an issue during the download process'
      );
    }
  };

  const updateContractStatus = (
    contract: IContract,
    newStatus: ContractStatus
  ) => {
    if (analytics) {
      trackEvent('Update Mezzanine Contract Status', analytics);
    }
    if (contractsResponse?.data === undefined) {
      return;
    }
    const contractsData = contractsResponse?.data;
    const index = _.findIndex(
      contractsData,
      x => x.contractId === contract.contractId
    );
    if (index === -1) {
      return;
    }
    contractsData[index].status = newStatus;
    setContracts({
      status: true,
      statusCode: 205,
      message: 'Contract Activated',
      data: contractsData,
    });
  };

  const handlePauseContract = async (contract: IContract) => {
    try {
      if (analytics) {
        trackEvent('Pause Mezzanine Contract', analytics);
      }
      const result = await mentaport?.pauseContract(contract);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Paused);
        dialog.notify(
          MessageStatus.Success,
          'Blockchain Contracts',
          'The contract has been paused'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Blockchain Contracts',
          'There was an issue pausing the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Blockchain Contracts',
        'There was an error pausing the contract'
      );
    }
  };

  const handleResumeContract = async (contract: IContract) => {
    try {
      if (analytics) {
        trackEvent('Resume Mezzanine Contract', analytics);
      }
      const result = await mentaport?.resumeContract(contract);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Active);
        dialog.notify(
          MessageStatus.Success,
          'Blockchain Contracts',
          'The contract has been resumed'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Blockchain Contracts',
          'There was an issue resuming the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Blockchain Contracts',
        'There was an error resuming the contract'
      );
    }
  };

  const getManagementButtons = () => {
    return [
      <Button
        marginLeft={10}
        variant="pink"
        size="sm"
        onClick={() => {
          handleCreateContract();
        }}
      >
        Create Contract
      </Button>,
    ];
  };

  return (
    <ContractListComponent
      managementPageProps={{
        title: 'CONTRACTS',
        buttons: getManagementButtons(),
        path: '',
        isBlockchain: false,
        hideBackButton: true,
        isLoading: contractsResponseLoading,
        placeholder: getPlaceHolder(),
        breadcrumbs: [],
        previousLocation: Routes.Home,
      }}
      environments={[
        {
          contracts:
            contractsResponse?.data?.filter(
              x => x.environment === ContractEnvironment.Mezzanine
            ) ?? [],
          environmentType: ContractEnvironment.Mezzanine,
        },
      ]}
      onEditContract={(contractId: string) => {
        navigate(`/contracts/mezzanine/edit/${contractId}`);
      }}
      onPauseContract={(contract: IContract): Promise<void> => {
        return handlePauseContract(contract);
      }}
      onResumeContract={(contract: IContract): Promise<void> => {
        return handleResumeContract(contract);
      }}
      onActivateContract={(contract: IContract): Promise<void> => {
        return handleActivate(contract);
      }}
      onManageContractRules={function (
        contractId: string,
        contractName: string
      ): void {
        navigate(`/contracts/mezzanine/${contractId}/${contractName}/rules`);
      }}
      onUsersDownLoad={function (contract: IContract): Promise<void> {
        return handleDownload(contract);
      }}
      onShowContractAnalytics={function (
        contractId: string,
        contractName: string
      ): void {
        navigate(
          `/contracts/mezzanine/${contractId}/${contractName}/analytics`
        );
      }}
    />
  );
};

export const MezzanineListPage = withAuthProtection(Page);
