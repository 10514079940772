import _ from "lodash";
import { FunctionComponent } from "react";
import "./CustomLegend.scss";
import { DataSet } from "../../../../../data/base-chart-interface";

interface CustomLegendProps {
  showLegendItems?: boolean;
  dataSets: DataSet[];
}

export const CustomLegend: FunctionComponent<CustomLegendProps> = (props) => {
  const getLegendItems = () => {
    return _.map(props.dataSets, (dataSet) => {
      return (
        <div key={dataSet.label} className="RepChartCustomLegendItem">
          {props.showLegendItems && (
            <div
              className="RepChartCustomLegendItemColoredBall"
              style={{
                backgroundColor:
                  typeof dataSet.backgroundColor === "string"
                    ? dataSet.backgroundColor
                    : dataSet.backgroundColor[0],
              }}
            />
          )}
          <a>{dataSet.label}</a>
        </div>
      );
    });
  };

  return <div className="RepChartCustomLegend">{getLegendItems()}</div>;
};
