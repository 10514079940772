import { ChartType, TooltipOptions } from "chart.js";
import { useEffect, useState } from "react";
import {
  BaseChartProp,
  ChartClip,
  DataLabelStyle,
  DataSet,
} from "../../../../data/base-chart-interface";
import {
  ChartOptionBuilder,
  chartOptionBuilder,
} from "../../../../services/chart-option-service";

export const useChart = (
  chartProps: BaseChartProp
): [
  options: ChartOptionBuilder<ChartType>,
  labels: any[],
  dataSets: DataSet[]
] => {
  const [labels, setLabels] = useState<any[]>([]);
  const [dataSets, setDataSets] = useState<DataSet[]>([]);
  const [showLegend, setShowLegend] = useState<boolean | undefined>(false);
  const [title, setTitle] = useState<string | undefined>("");
  const [responsive, setResponsive] = useState<boolean>(false);
  const [maintainAspectRatio, setMaintainAspectRatio] = useState<
    boolean | undefined
  >(false);
  const [aspectRatio, setAspectRatio] = useState<number>();
  const [showZeroForEmptyLabel, setShowZeroForEmptyLabel] = useState<
    boolean | undefined
  >(false);
  const [clip, setClip] = useState<number | false | ChartClip>(false);
  const [dataLabelStyle, setDataLabelStyle] = useState<DataLabelStyle>();
  const [customTooltip, setCustomTooltip] = useState<
    TooltipOptions | undefined
  >();
  const [padding, setPadding] = useState<number>();

  useEffect(() => {
    if (chartProps) {
      setTitle(chartProps.title);
      setShowLegend(chartProps.showLegend);
      setResponsive(chartProps.responsive);
      setMaintainAspectRatio(chartProps.maintainAspectRatio);
      setAspectRatio(chartProps.aspectRatio);
      setShowZeroForEmptyLabel(chartProps.showZeroForEmptyLabel);
      setClip(chartProps.clip || false);
      setDataLabelStyle(chartProps.dataLabelStyle);
      if (chartProps.chartData) {
        setLabels(chartProps.chartData.x);
        setDataSets(chartProps.chartData.dataSets);
      }
      setCustomTooltip(chartProps.customToolTip);
      setPadding(chartProps.padding);
    }
  }, [chartProps]);

  const options = chartOptionBuilder(chartProps.axisStyle)
    .setDataLabelStyle(dataLabelStyle ?? { display: false })
    .setResponsive(responsive)
    .maintainAspectRatio(maintainAspectRatio)
    .setAspectRatio(aspectRatio)
    .showLegend(showLegend ?? false)
    .showTitle(title ? true : false)
    .setTitle(title ?? "")
    .showZeroForEmptyLabel(showZeroForEmptyLabel)
    .setChartClip(clip)
    .serPadding(padding)
    .showCustomTooltip(customTooltip);

  return [options, labels, dataSets];
};
