import { withAuthProtection } from '../../services/protect-route-element';
import { Flex, Box } from '@chakra-ui/react';
import './ContractDetailsPage.scss';
import { TotalVolumeCard } from './components/TotalVolumeCard';
import { MetricsCard } from './components/MetricsCard';
import { InfoGraphicCard } from './components/InfoGraphicCard';
import {
  InfoGraphicCardData,
  InfoGraphicCardLabel,
} from './data/info-graphic-card-data';
import { PopularLocationsCard } from './components/PopularLocationsCard';
import { LocationElement } from './data/location-element';
import { LocationFinder } from './components/LocationFinder';
import { TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const totalValueCardData = { label: 'Total Volume', value: '2.8K' };
const MeticsCardData = [
  { label: 'Items', value: '3.3K' },
  { label: 'Owners', value: '1.8K' },
  { label: 'Volume in Primary Sales', value: '204.50' },
  { label: 'Volume in Secondary Sales', value: '2.5K' },
  { label: 'Floor Price', value: '1.8' },
];

const PopularLocationsData: LocationElement[] = [
  { label: 'Twin Peaks, San Francisco, CA', id: '#t17847', volume: 317.25 },
  { label: 'South Beach, San Francisco, CA', id: '#t179811', volume: -234.1 },
  { label: 'SoHo, New York City, NY', id: '#t25050', volume: 177.7 },
  { label: 'Midtown, New York City, NY', id: '#t18655', volume: 66.6 },
  { label: 'Las Vegas Boulevard, Las Vegas, NV', id: '#t179811', volume: 43.0 },
  { label: 'Downtown, San Francisco, CA', id: '#t179811', volume: -23.7 },
  {
    label: 'Financial District, San Francisco, CA',
    id: '#t179811',
    volume: 13.4,
  },
];

const getHeight = () => {
  let vhResult = 0.04336399474375821 * window.innerHeight + 9.241787122207626;
  if (vhResult > 100) {
    vhResult = 100;
  }

  return `${vhResult}vh`;
};

const Page = (props: any) => {
  let { id } = useParams();
  const [height, setHeight] = useState(getHeight());

  useEffect(() => {
    window.addEventListener('resize', () => setHeight(getHeight()));
    return () =>
      window.removeEventListener('resize', () => setHeight(getHeight()));
  }, []);

  return (
    <div className="Contract_Details_Analytics">
      <Flex w={'100%'} className="SectionsWrapper" direction={'column'} gap={5}>
        <h1>Contract: {id}</h1>
        <Flex
          w={'100%'}
          justify={'space-between'}
          className="TopSections"
          direction={'row'}
          gap={'5'}
        >
          <Box
            className="TotalInteractionsCard"
            borderRadius={10}
            bg={'#1e1c1f'}
            w={'70%'}
            h={'10rem'}
          >
            <InfoGraphicCard
              x={InfoGraphicCardLabel}
              data={InfoGraphicCardData}
            />
          </Box>
          <Box className="TotalVolume" w={'30%'} h={'10rem'}>
            <TotalVolumeCard
              value={totalValueCardData.value}
              label={totalValueCardData.label}
            />
          </Box>
        </Flex>
        <Box
          className="MetricsCard"
          borderRadius={10}
          bg={'gray'}
          w={'100%'}
          h={'7rem'}
        >
          <MetricsCard metrics={MeticsCardData} />
        </Box>
        <Flex
          w={'100%'}
          justify={'space-between'}
          className="BottomSection"
          direction={'row'}
          gap={'5'}
        >
          <Box
            className="PopularLocationsCard"
            borderRadius={10}
            bg={'gray'}
            w={'45%'}
            h={height}
          >
            <PopularLocationsCard data={PopularLocationsData} />
          </Box>
          <Flex
            direction={'column'}
            className="MapSectionWrapper"
            w={'55%'}
            h={height}
            gap={4}
          >
            <Box className="MapFinder" borderRadius={10} h={'3rem'}>
              <TextField
                variant="outlined"
                label="Location"
                sx={{ width: '100%' }}
                placeholder="Enter a query"
                InputProps={{
                  style: {
                    borderRadius: '12px',
                  },
                }}
                onChange={(event) => {
                  // setLocationData(event.target.value);
                }}
              />
            </Box>
            <Box className="MapCard" borderRadius={10} bg={'gray'} h={'100%'}>
              <LocationFinder
                data={{
                  lat: 25.761681,
                  lng: -80.191788,
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export const ContractDetailsPage = withAuthProtection(Page);
