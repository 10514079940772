import {
  LocationTypes,
  ILocation,
  AccessTypes,
  TimeTypes,
  IAccess,
  ITime,
} from '@mentaport/types-common';
import { getCountryFromCode } from '../data/places';
import { Option } from '../components/commons/OptionList/OptionList';
import {
  BlockchainTypes,
  ContractEnvironment,
} from '@mentaport/types-supplement';
import { LocalIcons } from '../assets/icons/icons';

export const regenerateOptionsWithCustomNewValue = (
  options: Option[],
  value: any
): Option[] => {
  const tempOptions = [...options];
  const index = tempOptions.findIndex(x => x?.isSelected === true);
  if (tempOptions[index]) {
    tempOptions[index].value = value;
  }
  return tempOptions;
};

export const regenerateOptionsWithNewValue = (
  newValue: any,
  defaultOptions: Option[]
): Option[] => {
  const tempOptions = [...defaultOptions];
  tempOptions.forEach(element => {
    element.isSelected = false;
  });
  const index = tempOptions.findIndex(x => x?.value === newValue);
  if (tempOptions[index]) {
    tempOptions[index].isSelected = true;
  } else {
    const customIndex = tempOptions.findIndex(x => x?.isCustom === true);
    tempOptions[customIndex].isSelected = true;
  }
  return tempOptions;
};

export const getDefaultContractEnvironmentOptions = (
  isMezzanine: boolean
): Option[] => [
  {
    id: 1,
    label: 'Testnet',
    value: ContractEnvironment.Testnet,
    isSelected: !isMezzanine,
  },
  {
    id: 2,
    label: 'Mezzanine',
    value: ContractEnvironment.Mezzanine,
    isSelected: isMezzanine,
  },
  {
    id: 3,
    label: 'Localhost',
    value: ContractEnvironment.Localhost,
  },
  {
    id: 4,
    label: 'Mainnet',
    value: ContractEnvironment.Mainnet,
  },
];

export const getDefaultBlockchainTypeOptions = (
  isMezzanine: boolean
): Option[] => [
  {
    id: 1,
    label: 'Polygon',
    value: BlockchainTypes.Polygon,
    icon: LocalIcons.PolygonLogo,
    isSelected: true,
  },
  {
    id: 2,
    label: 'Sui',
    value: BlockchainTypes.Sui,
    icon: LocalIcons.SuiLogo,
  },
  {
    id: 3,
    label: 'Ethereum',
    value: BlockchainTypes.Ethereum,
    icon: LocalIcons.EthereumLogo,
  },
];

export const getDefaultWhereOptions = (): Option[] => [
  {
    id: 1,
    label: 'ANYWHERE',
    value: LocationTypes.None,
    isSelected: true,
  },
  {
    id: 2,
    label: 'BUILD CUSTOM',
    isCustom: true,
    showValue: true,
  },
];

export const getDefaultWhenOptions = (): Option[] => [
  {
    id: 1,
    label: 'ANYTIME',
    value: TimeTypes.None,
    isSelected: true,
  },
  {
    id: 2,
    label: 'BUILD CUSTOM',
    isCustom: true,
    showValue: true,
  },
];

export const getDefaultWhoOptions = (): Option[] => [
  {
    id: 1,
    label: 'PUBLIC',
    value: AccessTypes.Public,
    isSelected: true,
  },
  {
    id: 2,
    label: 'BUILD CUSTOM',
    value: AccessTypes.Custom,
    isCustom: true,
    showValue: true,
  },
];

export const defaultLocation: ILocation = {
  type: LocationTypes.None,
  mainCoordinate: {
    radius: 0,
    latitude: 0,
    longitude: 0,
  },
};

export const locationViewRegistry = new Map<
  LocationTypes,
  (location: ILocation) => string
>([
  [
    LocationTypes.Radius,
    location => {
      return `Lat: ${location.mainCoordinate.latitude}, Long: ${location.mainCoordinate.longitude}, radius: ${location.mainCoordinate.radius} m`;
    },
  ],
  [
    LocationTypes.Country,
    location => {
      if (!location.place?.countryCode) {
        return '';
      }
      return getCountryFromCode(location.place?.countryCode).countryName;
    },
  ],
  [
    LocationTypes.City,
    location => {
      if (!location.place?.city) {
        return '';
      }
      return location.place?.city;
    },
  ],
  [
    LocationTypes.State,
    location => {
      if (!location.place?.state) {
        return '';
      }
      return location.place?.state;
    },
  ],
  [
    LocationTypes.Polygon,
    location => {
      if (!location.polygonList) {
        return '';
      }
      return `${location.polygonList.length} points`;
    },
  ],
]);

export const getWhoValue = (whiteList: string[]) => {
  const whoValue = whiteList?.join(', ');
  const newValue =
    whoValue && whoValue?.length >= 30
      ? whoValue?.substring(0, 30) + '...'
      : whoValue;
  return newValue;
};

export const setOptionsValuesForRule = (
  setRuleElement: React.Dispatch<React.SetStateAction<any>>,
  ruleElement: IAccess | ILocation | ITime,
  setOptions: React.Dispatch<React.SetStateAction<Option[]>>,
  defaultOptions: Option[],
  newValue: string
) => {
  setRuleElement(ruleElement);
  const newWhoOptions = regenerateOptionsWithNewValue(
    ruleElement.type,
    defaultOptions
  );
  setOptions(regenerateOptionsWithCustomNewValue(newWhoOptions, newValue));
};

export const setRule = (
  ruleValue: IAccess | ILocation | ITime | undefined,
  defaultValue: TimeTypes | AccessTypes | LocationTypes,
  newValue: string,
  setRuleElement: React.Dispatch<React.SetStateAction<any>>,
  setOptions: React.Dispatch<React.SetStateAction<Option[]>>,
  options: Option[]
) => {
  if (ruleValue) {
    const ruleElement = ruleValue ?? {
      type: defaultValue,
    };
    setOptionsValuesForRule(
      setRuleElement,
      ruleElement,
      setOptions,
      options,
      newValue
    );
  }
};
