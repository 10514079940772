import { withAuthProtection } from '../../services/protect-route-element';
import { Stack, Button, Heading, Flex, Skeleton, Link } from '@chakra-ui/react';
import './CreateKeyPage.scss';
import { useState, useEffect } from 'react';
import { trackEvent, useAnalytics } from '../../hooks/use-analytics';
import { useDashboardApi } from '../../hooks/use-dashboard-api';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';

const Page = () => {
  const analytics = useAnalytics();
  const apiClient = useDashboardApi();
  const [createdKeys, setCreatedKeys] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const dialog = useDialog();

  const fetchKeys = async () => {
    try {
      setLoading(true);
      const apiKeys = await apiClient?.fetchApiKeys();
      if (apiKeys) {
        setCreatedKeys(apiKeys);
      }
    } catch (ex: any) {
      console.error(ex);
      dialog.notify(
        MessageStatus.Error,
        'API Key ',
        'There was a problem fetching the API Key.'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKeys();
  }, [apiClient]);

  const handleCreateApiKey = async () => {
    if (analytics) {
      trackEvent('Get API Key', analytics);
    }
    if (createdKeys?.length !== 0) {
      dialog.notify(
        MessageStatus.Info,
        'API Key Creation',
        'You already have an API Key.'
      );
      return;
    }
    try {
      setLoading(true);
      const apiKey = await apiClient?.createApiKey();
      if (apiKey) {
        dialog.notify(
          MessageStatus.Success,
          'API Key Creation',
          'The API Key was created successfully.'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'API Key Creation',
          'There was a problem creating the API Key.'
        );
      }
    } catch (ex) {
      dialog.notify(
        MessageStatus.Error,
        'API Key Creation',
        'There was a problem creating the API Key.'
      );
    } finally {
      fetchKeys();
      setLoading(false);
    }
  };

  const renderKeys = () => {
    if (createdKeys && createdKeys?.length > 0) {
      return (
        <p>
          You already have a key. Go to the{' '}
          <Link color={'#fb448b'} href="/home">
            dashboard
          </Link>{' '}
          to start using it.
        </p>
      );
    }
    return <></>;
  };

  const getPlaceHolder = () => {
    return (
      <Stack>
        <Skeleton w={'20rem'} bgColor={'black'} height="2rem" />;
      </Stack>
    );
  };

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      className="SectionsWrapper"
      direction={'column'}
      gap={5}
      alignItems="center"
      justifyContent="center"
    >
      <Heading size={{ base: 'xs', lg: 'sm' }} fontWeight="bolder">
        API KEY
      </Heading>
      {(loading || !createdKeys) && getPlaceHolder()}
      {!loading && renderKeys()}
      <Button
        key="button"
        w={'fit-content'}
        variant="pink"
        opacity={loading || createdKeys?.length !== 0 ? '0.5' : '1'}
        isDisabled={loading || createdKeys?.length !== 0}
        onClick={handleCreateApiKey}
      >
        Create API Key
      </Button>
    </Flex>
  );
};

export const CreateKeyPage = withAuthProtection(Page);
