import { FunctionComponent, useEffect, useState } from 'react';
import { SelectOption } from '../Select/Select';
import { Box, Flex, Grid, Image, Skeleton, Text } from '@chakra-ui/react';
import { LocalIcons } from '../../../assets/icons/icons';
import './OptionList.scss';
import _ from 'lodash';

export interface OptionListProps {
  sectionTitle: string;
  options: Option[];
  isLoading?: boolean;
  onSelectValue: (option: Option) => void;
  disabled?: boolean;
}

export interface Option extends SelectOption {
  id: number;
  showValue?: boolean;
  isSelected?: boolean;
  isCustom?: boolean;
}

export const OptionList: FunctionComponent<OptionListProps> = props => {
  const [options, setOptions] = useState<Option[]>(props.options);

  const selectElement = (option: Option) => {
    const tempOptions = [...options];
    tempOptions.map(x => (x.isSelected = false));
    const index = tempOptions.findIndex(x => x.id === option.id);
    tempOptions[index] = option;
    option.isSelected = !option.isSelected;
    setOptions(tempOptions);
  };

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  return (
    <Box className="OptionListContainer">
      <Text className="OptionListTitle">{props.sectionTitle}</Text>
      <Grid className="OptionList">
        {options.map((option, index) =>
          props.isLoading ? (
            <Skeleton key={index} height={'4rem'} w={'100%'} />
          ) : (
            <Flex
              key={index}
              className="OptionBox"
              onClick={() => {
                if (props.disabled) {
                  return;
                }
                selectElement(option);
                props.onSelectValue(option);
              }}
            >
              <Flex
                opacity={props.disabled === true ? 0.1 : 1}
                className="OptionBoxLabel"
                fontWeight={option.isSelected ? 700 : 400}
              >
                {option.icon && (
                  <Image className="OptionBoxIcon" src={option.icon} />
                )}
                <Flex className="ValueSubTitle" direction={'column'}>
                  <Text>{option.label.toUpperCase()}</Text>
                  {option.showValue && option.isSelected && (
                    <Text className="OptionListValue">{option.value}</Text>
                  )}
                </Flex>
              </Flex>
              {option.isCustom ? (
                <Image
                  h={'18px'}
                  src={LocalIcons.OptionCustom}
                  opacity={
                    props.disabled || !option.isSelected ? '50%' : '100%'
                  }
                />
              ) : (
                <Image
                  h={'18px'}
                  src={LocalIcons.OptionCheckmark}
                  opacity={
                    props.disabled || !option.isSelected ? '10%' : '100%'
                  }
                />
              )}
            </Flex>
          )
        )}
      </Grid>
    </Box>
  );
};
