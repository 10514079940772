import { MentaportSupplementSDK } from '@mentaport/supplement';
import {
  IContractCreate,
  IContract,
  ContractStatus,
} from '@mentaport/types-supplement';
import { Environment, IRule, IResults } from '@mentaport/types-common';
import { IUpdateRule, IUpdateContract } from '@mentaport/types-supplement';
import { configuration } from '../../config/configuration';

export class MentaportService {
  supClient: MentaportSupplementSDK;
  constructor(supClient: MentaportSupplementSDK) {
    this.supClient = supClient;
    this.supClient.setClientEnv(this.getMentaportEnvironment());
  }

  private getMentaportEnvironment = (): Environment => {
    switch (configuration.mentaport.stageId) {
      case 'development':
        return Environment.DEVELOPMENT;
      case 'staging':
        return Environment.STAGING;
      case 'stable':
        return Environment.STABLE;
      case 'production':
        return Environment.PRODUCTION;
      default:
        return Environment.STABLE;
    }
  };

  public async createNewContract(
    contract: IContractCreate
  ): Promise<IContract> {
    const newContract = await this.supClient.contractSDK.createNewContract(
      contract
    );
    if (!newContract.data) {
      throw new Error('Contract creation failed');
    }
    return newContract.data;
  }

  public async createMezzanineContract(
    contract: IContractCreate
  ): Promise<IContract> {
    const newContract = await this.supClient.contractSDK.createNewContract(
      contract
    );
    if (!newContract.data) {
      throw new Error('Contract creation failed');
    }
    return newContract.data;
  }

  public async fetchContracts() {
    return await this.supClient.contractSDK.getContracts();
  }

  public async getContractById(contractId: string) {
    return await this.supClient.contractSDK.getContractById(contractId);
  }

  public async updateContract(contractId: string, contract: IUpdateContract) {
    return await this.supClient.contractSDK.updateContractById(
      contractId,
      contract
    );
  }

  public async activateContract(contract: IContract) {
    return await this.supClient.contractSDK.activateContract(
      contract.contractId
    );
  }

  public async fetchMezzanineUsers(contract: IContract) {
    return await this.supClient.contractSDK.getMezzanineById(
      contract.contractId
    );
  }

  public async pauseContract(contract: IContract) {
    return await this.supClient.contractSDK.updateContractStatusById(
      contract.contractId,
      ContractStatus.Paused
    );
  }

  public async resumeContract(contract: IContract) {
    return await this.supClient.contractSDK.updateContractStatusById(
      contract.contractId,
      ContractStatus.Active
    );
  }

  public async activateOnAlreadyDeployedContract(
    contract: IContract,
    contractAddress: string
  ) {
    return await this.supClient.contractSDK.activateDeployedContract(
      contract.contractId,
      contractAddress
    );
  }

  public async activateMezzanineContract(contract: IContract) {
    return await this.supClient.contractSDK.activateMezzanineContract(
      contract.contractId
    );
  }

  public async createNewRules(rules: IRule[]) {
    return await this.supClient.rulesSDK.createNewRules(rules);
  }

  public async updateRuleById(
    ruleId: string,
    contractId: string,
    rule: IUpdateRule
  ) {
    return await this.supClient.rulesSDK.updateRuleById(
      ruleId,
      contractId,
      rule
    );
  }

  public async getRuleById(
    ruleId: string,
    contractId: string
  ): Promise<IResults<IRule>> {
    return await this.supClient.rulesSDK.getRuleById(contractId, ruleId);
  }

  public async updateRule(
    ruleId: string,
    contractId: string,
    rule: IUpdateRule
  ): Promise<IResults<IUpdateRule>> {
    return await this.supClient.rulesSDK.updateRuleById(
      ruleId,
      contractId,
      rule
    );
  }

  public async getRules(contractId: string): Promise<IResults<IRule[]>> {
    return await this.supClient.rulesSDK.getAllRules(contractId);
  }
}
