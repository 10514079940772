import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Image,
  Avatar,
  useBreakpointValue,
  Icon,
  Button,
} from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { NavButton } from './NavButton';
import data from '../../../config/config.json';
import { useLocation, useNavigate } from 'react-router-dom';
import { authentication } from '../../services/authentication';

export const Navbar = () => {
  const navigate = useNavigate();
  const user = authentication.getUser();
  const pathArray = window.location.pathname.split('/');
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const location = useLocation();

  const userIsAddingRules = (option: any) => {
    return (
      isOptionActive(option) &&
      (location.pathname.includes('/rules/new') ||
        location.pathname.includes('/contracts/blockchain/new') ||
        location.pathname.includes('/contracts/mezzanine/new'))
    );
  };

  const isOptionActive = (option: any) => {
    return !!option.slug && location.pathname.indexOf(option.slug) !== -1;
  };

  return (
    <Flex as="section" minH="100vh" bg="#f1f1f1">
      <Flex
        flex="1"
        bg="bg-surface"
        boxShadow="sm"
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '5', sm: '1.5' }}
        px={{ base: '4', sm: '6' }}
        w={isDesktop ? 'auto' : '90px'}
      >
        <Stack justify="space-between" spacing="1" width="100%">
          <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
            <Stack align="center">
              <Image
                width={isDesktop ? '8.0625rem' : '7rem'}
                height={isDesktop ? '2.75rem' : '2rem'}
                cursor="pointer"
                style={{
                  objectFit: 'contain',
                }}
                src={isDesktop ? data.icon : data.smallIcon}
                alt={data.companyName}
                onClick={() => navigate('/home')}
              />
            </Stack>
            <Stack spacing="1">
              {data.Navbar.map((option, index) => {
                return (
                  <NavButton
                    key={index}
                    aria-current={
                      pathArray[1] === option.displayName ? 'page' : 'false'
                    }
                    backgroundColor={userIsAddingRules(option) ? '#97A3C0' : ''}
                    isButton={!!option.slug}
                    active={isOptionActive(option)}
                    label={option.displayName}
                    icon={option.icon}
                    onClick={() => {
                      return navigate(`/${option.slug}`);
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
          <Stack spacing={{ base: '5', sm: '6' }}>
            <HStack spacing="3">
              <Avatar
                boxSize="10"
                name={user?.name || ''}
                src={user?.picture || ''}
              />
              {isDesktop && (
                <Box>
                  <Text textAlign="left" fontWeight="medium" fontSize="sm">
                    {user?.name}
                  </Text>
                  <Text color="muted" fontSize="sm">
                    {user?.email}
                  </Text>
                </Box>
              )}
            </HStack>
            <Divider />
            <Button
              variant="black"
              marginBottom={5}
              onClick={() => {
                authentication.logout();
              }}
            >
              <Icon
                margin={1}
                as={FiLogOut}
                boxSize="3"
                color="black"
                width={'1.25rem'}
                height={'1.25rem'}
              />
              {isDesktop ? 'Logout' : ''}
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
