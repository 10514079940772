import { useState, useEffect } from 'react';
import { configuration } from '../../config/configuration';
import { useAuth0 } from '@auth0/auth0-react';
import { DashboardApiService } from '../services/dashboard-api-service';

export function useDashboardApi() {
  const [dashboardApi, setDashboardApi] = useState<DashboardApiService>();
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const authOptions = {
    authorizationParams: {
      audience: configuration.auth0.audience,
      scope: 'read:accessTokenSilently',
    },
  };
  useEffect(() => {
    const loadDashboardApi = async () => {
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently(authOptions);
      } catch (ex) {
        accessToken = await getAccessTokenWithPopup(authOptions);
      }

      setDashboardApi(new DashboardApiService(accessToken ?? ''));
    };
    loadDashboardApi();
  }, []);

  return dashboardApi;
}
