import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { FunctionComponent } from "react";
import { Line } from "react-chartjs-2";
import { CustomLegend } from "../components/CustomLegend/CustomLegend";
import { useLineChart } from "../hooks/use-line-chart";
import "./LineChart.scss";
import { BaseChartProp } from "../../../../data/base-chart-interface";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface LineChartProp extends BaseChartProp {
  tension?: number;
  pointSize?: number;
  borderWidth?: number;
  pointBorderColor?: string;
  pointColor?: string;
  pointBorderWidth?: number;
}

export const LineChart: FunctionComponent<LineChartProp> = (props) => {
  const [options, baseProps, pointBorderColor, pointColor] =
    useLineChart(props);

  const getData = () => {
    return {
      labels: baseProps.labels,
      datasets: baseProps.dataSets.map((dataSet: any) => {
        return {
          label: dataSet.label ?? "",
          data: dataSet.data,
          borderColor: dataSet.borderColor,
          backgroundColor: dataSet.backgroundColor,
          pointBackgroundColor: pointColor,
          pointBorderColor: pointBorderColor,
        };
      }),
    };
  };

  return (
    <div className="RepLineChart" style={{ height: "100%" }}>
      <div className="LineChartContainer">
        <Line options={options.build() as any} data={getData()} />
      </div>
      {props.customLegend && (
        <CustomLegend
          showLegendItems={props.customLegend}
          dataSets={props.chartData.dataSets}
        />
      )}
    </div>
  );
};
