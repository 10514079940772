import { IconButton } from "@chakra-ui/react";
import { Box, Button, Card, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { LineChart } from "../../../components/commons/Charts/Line/LineChart";
import { LocalIcons } from "../../../assets/icons/icons";

export interface InfoGraphicCardProps {
  x: string[];
  data: number[];
}

enum InfoGraphicCardFilter {
  Thirty,
  Sixty,
  Ninety,
  Max,
}

const getGraphicWidth = () => {
  return 0.00816326530612245 * window.innerWidth - 1.591836734693878;
};

export const InfoGraphicCard: FunctionComponent<InfoGraphicCardProps> = (
  props
) => {
  const [x, setX] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);
  const [filter, setFilter] = useState(InfoGraphicCardFilter.Max);
  const [graphicWidth, setGraphicWidth] = useState(getGraphicWidth());

  const calculateTotalValue = () => {
    let total = 0;
    data.map((x) => {
      return (total += x);
    });
    if (total >= 1000) {
      return `${total / 1000}K`;
    }
    return `${total}`;
  };

  useEffect(() => {
    if (filter === InfoGraphicCardFilter.Max) {
      setX(props.x);
      setData(props.data);
    } else if (filter === InfoGraphicCardFilter.Thirty) {
      setX(props.x.filter((_, i) => i < 30));
      setData(props.data.filter((_, i) => i < 30));
    } else if (filter === InfoGraphicCardFilter.Sixty) {
      setX(props.x.filter((_, i) => i < 60));
      setData(props.data.filter((_, i) => i < 60));
    } else {
      setX(props.x.filter((_, i) => i < 90));
      setData(props.data.filter((_, i) => i < 90));
    }
  }, [props, filter]);

  useEffect(() => {
    window.addEventListener("resize", () => setGraphicWidth(getGraphicWidth()));
    return () =>
      window.removeEventListener("resize", () =>
        setGraphicWidth(getGraphicWidth())
      );
  }, []);

  return (
    <Card
      variant={"elevation"}
      sx={{ backgroundColor: "#1e1c1f", borderRadius: "10px", height: "100%" }}
    >
      <Box display={"flex"} height={"100%"} padding={"1rem"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
        >
          <IconButton
            aria-label="User"
            colorScheme="pink"
            width={"2.5rem"}
            height={"2.5rem"}
            icon={<img src={LocalIcons.UserV2} className="HomePageUserIcon" />}
          />
          <Box display={"flex"} gap={"0.5rem"}>
            <Typography variant="h5" color={"white"}>
              {calculateTotalValue()}
            </Typography>
            <IconButton
              aria-label="Arrow"
              colorScheme="pink"
              borderRadius={100}
              minWidth={"1.25rem"}
              height={"1.25rem"}
              icon={
                <img
                  src={LocalIcons.DiagonalArrow}
                  className="HomePageDiagonalArrowIcon"
                />
              }
            />
          </Box>
          <Typography
            variant="subtitle2"
            color={"hotpink"}
            whiteSpace={"nowrap"}
          >
            Total Interactions
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Box
            display={"flex"}
            gap={"1rem"}
            justifyContent={"flex-end"}
            marginRight={"1rem"}
          >
            <Button onClick={() => setFilter(InfoGraphicCardFilter.Thirty)}>
              <Typography
                variant="caption"
                display="block"
                color={"white"}
                textTransform={"none"}
              >
                30 Days
              </Typography>
            </Button>
            <Button onClick={() => setFilter(InfoGraphicCardFilter.Sixty)}>
              <Typography
                variant="caption"
                display="block"
                color={"white"}
                textTransform={"none"}
              >
                60 Days
              </Typography>
            </Button>
            <Button onClick={() => setFilter(InfoGraphicCardFilter.Ninety)}>
              <Typography
                variant="caption"
                display="block"
                color={"white"}
                textTransform={"none"}
              >
                90 Days
              </Typography>
            </Button>
            <Button onClick={() => setFilter(InfoGraphicCardFilter.Max)}>
              <Typography
                variant="caption"
                display="block"
                color={"white"}
                textTransform={"none"}
              >
                MAX
              </Typography>
            </Button>
          </Box>
          <Box padding={"1rem"}>
            <LineChart
              axisStyle={[
                {
                  id: "x",
                  showScales: false,
                },
                {
                  id: "y",
                  showScales: false,
                },
              ]}
              chartData={{
                x: x,
                dataSets: [
                  {
                    data: data,
                    borderColor: "white",
                    backgroundColor: "transparent",
                  },
                ],
              }}
              responsive={true}
              aspectRatio={graphicWidth}
              borderWidth={1.5}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
