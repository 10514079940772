import { useDataFetcher } from './use-data-fetcher';
import { useDashboardApi } from './use-dashboard-api';
import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';

const apiKeysStorage = atomWithStorage<string[]>('apiKeys', []);

export const useApiKeys = (): [apikeys: string[], isLoading: boolean] => {
  const [apiKeysStorageAtom, setApiKeysStorageAtom] = useAtom(apiKeysStorage);
  const apiClient = useDashboardApi();
  const [apiKeys, isLoading] = useDataFetcher<string[]>({
    serviceCall: () => {
      return apiClient?.fetchApiKeys();
    },
    dependencies: [apiClient],
    conditionForExecution:
      apiClient !== undefined &&
      (apiKeysStorageAtom === undefined || apiKeysStorageAtom.length === 0),
    onFinish(response) {
      setApiKeysStorageAtom(response ?? []);
    },
  });
  return [apiKeysStorageAtom ?? apiKeys ?? [], isLoading];
};
