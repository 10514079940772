import { Box, Button, Flex, Text, useDisclosure, Link } from '@chakra-ui/react';
import { withAuthProtection } from '../../services/protect-route-element';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMentaport } from '../../hooks/use-mentaport';
import { useEffect, useState } from 'react';
import './ManageRulePage.scss';
import {
  IRule,
  IAccess,
  AccessTypes,
  ILocation,
  LocationTypes,
  RuleSchemas,
  RuleTypes,
  ITime,
  TimeTypes,
} from '@mentaport/types-common';
import {
  Option,
  OptionList,
} from '../../components/commons/OptionList/OptionList';
import {
  SideDrawerType,
  SideDrawer,
  WhenRuleSection,
  WhoRuleSection,
  WhereRuleSection,
} from '../../components/commons/SideDrawer/SideDrawer';
import {
  defaultLocation,
  getDefaultWhenOptions,
  getDefaultWhereOptions,
  getDefaultWhoOptions,
  locationViewRegistry,
  getWhoValue,
  setRule,
} from '../../helpers/options-helper';
import { MessageStatus } from '../../services/dialog';
import { useDialog } from '../../hooks/use-dialog';
import { getDates } from './helpers/date-helper';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { getEditOrCreate } from '../../helpers/pages-helper';
import JSConfetti from 'js-confetti';
import { localStorageService } from '../../services/local-storage-service';
import { getPlaceHolder } from '../../helpers/list-helper';
import { MentaportInput } from '../../components/commons/CreateContractInput/MentaportInput';
import { trackEvent, useAnalytics } from '../../hooks/use-analytics';

export const Page = () => {
  const dialog = useDialog();
  const { contractId, contractName, ruleId } = useParams();
  const navigate = useNavigate();
  const mentaportService = useMentaport();
  const [ruleName, setRuleName] = useState('');
  const [ruleAmount, setRuleAmount] = useState<number>(10);
  const [ruleDescription, setRuleDescription] = useState<string>('');
  const [ruleWho, setRuleWho] = useState<IAccess>({ type: AccessTypes.Public });
  const [ruleWhen, setRuleWhen] = useState<ITime>({ type: TimeTypes.None });
  const [ruleWhere, setRuleWhere] = useState(defaultLocation);
  const [isSendingRule, setIsSendingRule] = useState(false);
  const [locationType, setLocationType] = useState(LocationTypes.None);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sideDrawerType, setSideDrawerType] = useState(SideDrawerType.Who);
  const analytics = useAnalytics();
  const [whoOptions, setWhoOptions] = useState<Option[]>(
    getDefaultWhoOptions()
  );
  const [whenOptions, setWhenOptions] = useState<Option[]>(
    getDefaultWhenOptions()
  );
  const [whereOptions, setWhereOptions] = useState<Option[]>(
    getDefaultWhereOptions()
  );
  const [sideBarContent, setSideBarContent] = useState<
    IAccess | ITime | ILocation | undefined
  >();
  const location = useLocation();

  const [_, isLoadingRuleData] = useDataFetcher({
    serviceCall: () => {
      if (!contractId || !ruleId) {
        return;
      }
      return mentaportService?.getRuleById(ruleId, contractId);
    },
    onFinish: response => {
      setRuleName(response?.data?.name ?? '');
      setRuleDescription(response?.data?.description ?? '');
      setRuleAmount(response?.data?.amount ?? 0);
      setWho(response);
      setWhen(response);
      setWhere(response);
    },
    onError: error => {
      dialog.notify(
        MessageStatus.Error,
        'Rule fetching',
        error?.data?.message ?? 'There was an error fetching the rule'
      );
      console.error(error);
      navigate(-1);
    },
    dependencies: [mentaportService],
    conditionForExecution:
      ruleId !== undefined && mentaportService !== undefined,
  });

  const setWhere = (response: any) => {
    setRule(
      response?.data?.location,
      LocationTypes.None,
      getLocationValue(response?.data?.location) ?? '',
      setRuleWhere,
      setWhereOptions,
      getDefaultWhereOptions()
    );
  };

  const setWhen = (response: any) => {
    setRule(
      response?.data?.time,
      TimeTypes.None,
      getDates(response?.data?.time) ?? '',
      setRuleWhen,
      setWhenOptions,
      getDefaultWhenOptions()
    );
  };
  const setWho = (response: any) => {
    setRule(
      response?.data?.access,
      AccessTypes.Public,
      getWhoValue(response?.data?.access?.whitelist ?? []),
      setRuleWho,
      setWhoOptions,
      getDefaultWhoOptions()
    );
  };

  const buildRule = (): IRule | undefined => {
    if (!contractId || !ruleWhen || !ruleWho || !ruleWhere) {
      return;
    }
    return {
      name: ruleName,
      description: ruleDescription,
      access: {
        type: ruleWho.type,
        whitelist: ruleWho.whitelist,
      },
      id: contractId,
      location: ruleWhere,
      amount: +ruleAmount,
      schema: RuleSchemas.Contract,
      time: ruleWhen,
      type: location.pathname.includes(RuleTypes.Mezzanine.toLocaleLowerCase())
        ? RuleTypes.Mezzanine
        : RuleTypes.Mint,
    };
  };

  const clearSections = () => {
    setRuleWho({ type: AccessTypes.Public });
    setRuleWhen({ type: TimeTypes.None });
    setRuleWhere(defaultLocation);
    setWhoOptions(getDefaultWhoOptions());
    setWhenOptions(getDefaultWhenOptions());
    setWhereOptions(getDefaultWhereOptions());
  };

  useEffect(() => {
    clearSections();
  }, [location]);

  const handleSaveRule = async (
    needRedirect: boolean,
    createNewOne: boolean
  ) => {
    setIsSendingRule(true);
    const rule = buildRule();
    if (!rule || !contractId) {
      dialog.notify(
        MessageStatus.Error,
        `${getEditOrCreate(!ruleId)} Rule`,
        'There is a problem with the contractId or some file of the rule'
      );
      return;
    }
    try {
      if (ruleId !== undefined) {
        if (analytics) {
          trackEvent(`${getEditOrCreate(false)} Rule`, analytics);
        }
        await mentaportService?.updateRuleById(ruleId, contractId, {
          access: rule.access,
          amount: rule.amount,
          description: rule.description,
          location: rule.location,
          name: rule.name,
          status: rule.status,
          time: rule.time,
          type: rule.type,
          updatedAt: new Date().toString(),
        });
      } else {
        if (analytics) {
          trackEvent(`${getEditOrCreate(true)} Rule`, analytics);
        }
        await mentaportService?.createNewRules([rule]);
        dialog.notify(
          MessageStatus.Success,
          `${getEditOrCreate(!ruleId)} Rule`,
          'Your rule is ready.'
        );
      }

      if (localStorageService.isFirstRule() === true) {
        localStorageService.setIsFirstRule(false);
        const confetti = new JSConfetti();
        confetti.addConfetti();
      }
      const contractsRoot = getContractEnvironment();
      if (needRedirect) {
        navigate(
          `/contracts/${contractsRoot.toLowerCase()}/${contractId}/${contractName}/rules`
        );
      }
      if (createNewOne) {
        navigate(
          `/contracts/${contractsRoot.toLowerCase()}/${contractId}/${contractName}/rules/new`
        );
      }
    } catch (error: any) {
      dialog.notify(
        MessageStatus.Error,
        `${getEditOrCreate(!ruleId)} Rule`,
        error?.data.message
      );
    } finally {
      setIsSendingRule(false);
      clearSections();
    }
  };

  const getContractEnvironment = () => {
    return location.pathname.startsWith('/contracts/blockchain')
      ? 'Blockchain'
      : 'Mezzanine';
  };

  const getLocationValue = (location: ILocation | undefined) => {
    if (!location) {
      return;
    }
    return locationViewRegistry.get(location.type)?.(location);
  };

  const handleWhoSectionChange = (rule: WhoRuleSection) => {
    const tempOptions = [...whoOptions];
    const customIndex = tempOptions.findIndex(
      (x: Option) => x.isCustom === true && x.isSelected
    );
    tempOptions[customIndex].label = rule.label;
    const value = getWhoValue(rule.access.whitelist ?? []);
    tempOptions[customIndex].value = value;
    setWhoOptions(tempOptions);
    setRuleWho(rule.access);
    if (analytics) {
      trackEvent(`Set Who Section on Rule`, analytics);
    }
  };

  const handleWhenSectionChange = (rule: WhenRuleSection) => {
    const tempOptions = [...whenOptions];
    const customIndex = tempOptions.findIndex(
      (x: Option) => x.isCustom === true
    );
    tempOptions[customIndex].label = rule.label;
    tempOptions[customIndex].value = getDates(rule.time);
    setWhenOptions(tempOptions);
    setRuleWhen(rule.time);
    if (analytics) {
      trackEvent(`Set When Section on Rule`, analytics);
    }
  };

  const handleWhereSectionChange = (rule: WhereRuleSection) => {
    const tempOptions = [...whereOptions];
    const customIndex = tempOptions.findIndex(
      (x: Option) => x.isCustom === true
    );
    tempOptions[customIndex].label = rule.label;
    tempOptions[customIndex].value = getLocationValue(rule.location);
    setWhereOptions(tempOptions);
    setRuleWhere(rule.location);
    if (analytics) {
      trackEvent(`Set Where Section on Rule`, analytics);
    }
  };

  const getPageContent = () => {
    return (
      <Flex className="NewRulePage">
        <SideDrawer
          sideDrawerType={sideDrawerType}
          sideBarContent={sideBarContent}
          isOpen={isOpen}
          onClose={onClose}
          locationType={locationType}
          onConfirm={rule => {
            switch (sideDrawerType) {
              case SideDrawerType.Who:
                handleWhoSectionChange(rule as WhoRuleSection);
                break;
              case SideDrawerType.When:
                handleWhenSectionChange(rule as WhenRuleSection);
                break;
              case SideDrawerType.Where:
                handleWhereSectionChange(rule as WhereRuleSection);
                break;
              default:
                break;
            }
          }}
        />
        <Box className="Container Left">
          <Box className="NewRulePageContractNameContainer" w={'100%'}>
            <Text className="NewRulePageTitle">Contract Name</Text>
            <Box className="NewRulePageContractName">{contractName}</Box>
          </Box>
          <Flex
            direction={'row'}
            width={'100%'}
            justify={'flex-start'}
            gap={'2rem'}
          >
            <Flex direction={'column'} gap={'1.2rem'} width={'50%'}>
              <MentaportInput
                isDisabled={ruleId !== undefined && isLoadingRuleData}
                title="Rule Name"
                value={ruleName}
                onChange={value => setRuleName(value)}
              />
            </Flex>
            <Flex direction={'column'} gap={'1.2rem'} width={'50%'}>
              <MentaportInput
                inputType="number"
                isDisabled={ruleId !== undefined && isLoadingRuleData}
                title="Amount"
                value={ruleAmount}
                onChange={valueAsNumber => setRuleAmount(valueAsNumber)}
              />
            </Flex>
          </Flex>
          <Flex direction={'column'} gap={'1.2rem'} width={'100%'}>
            <MentaportInput
              title="Description"
              isDisabled={ruleId !== undefined && isLoadingRuleData}
              value={ruleDescription}
              onChange={value => setRuleDescription(value)}
            />
          </Flex>
          <Box className="NewRulePageOptionList" w={'100%'}>
            <OptionList
              sectionTitle="Who"
              options={whoOptions}
              isLoading={
                isSendingRule || (ruleId !== undefined && isLoadingRuleData)
              }
              onSelectValue={option => {
                if (option.isCustom) {
                  setSideDrawerType(SideDrawerType.Who);
                  setSideBarContent(ruleWho);
                  onOpen();
                } else {
                  setRuleWho({
                    ...ruleWho,
                    type: option.value as AccessTypes,
                    whitelist: [],
                  });
                }
              }}
            />
          </Box>
          <Box className="NewRulePageOptionList" w={'100%'}>
            <OptionList
              sectionTitle="When"
              options={whenOptions}
              isLoading={
                isSendingRule || (ruleId !== undefined && isLoadingRuleData)
              }
              onSelectValue={option => {
                if (option.isCustom) {
                  setSideDrawerType(SideDrawerType.When);
                  setSideBarContent(ruleWhen);
                  onOpen();
                } else {
                  setRuleWhen({
                    ...ruleWhen,
                    type: option.value as TimeTypes,
                    endTime: undefined,
                    startTime: undefined,
                  });
                }
              }}
            />
          </Box>
          <Box className="NewRulePageOptionList" w={'100%'}>
            <OptionList
              sectionTitle="Where"
              options={whereOptions}
              isLoading={
                isSendingRule || (ruleId !== undefined && isLoadingRuleData)
              }
              onSelectValue={option => {
                if (option.isCustom) {
                  setSideDrawerType(SideDrawerType.Where);
                  setLocationType(LocationTypes.Polygon);
                  setSideBarContent(ruleWhere);
                  onOpen();
                } else {
                  setRuleWhere({
                    ...ruleWhere,
                    type: option.value as LocationTypes,
                    mainCoordinate: { latitude: 0, longitude: 0, radius: 0 },
                    place: undefined,
                    polygonList: undefined,
                  });
                }
              }}
            />
          </Box>
          <Box className="Divider"></Box>
          <Box className="ActionsSection">
            <Button
              isDisabled={
                isSendingRule ||
                !ruleWhen ||
                !ruleWhere ||
                (ruleId !== undefined && isLoadingRuleData)
              }
              className="CreateRule"
              variant={'dark'}
              onClick={() => {
                handleSaveRule(true, false);
              }}
            >
              {`Save Rule`}
            </Button>
            {!ruleId && (
              <Button
                isDisabled={
                  isSendingRule ||
                  !ruleWhen ||
                  !ruleWhere ||
                  (ruleId !== undefined && isLoadingRuleData)
                }
                className="CreateAnotherRule"
                variant={'pink'}
                onClick={() => {
                  handleSaveRule(false, true);
                }}
              >
                Save and Add Another
              </Button>
            )}
            <Button
              className="Cancel"
              variant={'dark'}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
        <Box className="Container Right">
          <Box className="TextSection">
            <Box className="Title">Rules</Box>
            <Box className="Definition">
              With Mentaport rules you can control the conditions by which a
              Contract can be executed.
            </Box>
          </Box>
          <Box className="ActionsSection">
            <Button className="GoToDocs" variant={'pink'}>
              <Link
                href="https://docs.mentaport.xyz/docs/Dashboard/rules"
                isExternal
              >
                Go to Docs
              </Link>
            </Button>
          </Box>
        </Box>
      </Flex>
    );
  };

  return (
    <ManagementPage
      title={`${getEditOrCreate(!ruleId)} my ${
        ruleName ? `'${ruleName}'` : ''
      } ${
        ruleName.endsWith('rule') || ruleName.endsWith('Rule') ? '' : 'rule'
      } `}
      path={''}
      isLoading={false}
      previousLocation={''}
      placeholder={getPlaceHolder()}
    >
      {getPageContent()}
    </ManagementPage>
  );
};
export const ManageRulePage = withAuthProtection(Page);
