import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { configuration } from '../../config/configuration';

export const useAnalytics = () => {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser>();

  useEffect(() => {
    const loadAnalytics = async () => {
      let response = AnalyticsBrowser.load({
        writeKey: configuration.june.writeKey,
      });
      setAnalytics(response as AnalyticsBrowser);
    };
    loadAnalytics();
  }, []);

  return analytics;
};

export const trackEvent = (
  eventName: string,
  analytics: AnalyticsBrowser | undefined
) => {
  if (!analytics) {
    return;
  }
  analytics?.track(eventName, { environment: configuration.environment });
};
