import { Box, Skeleton, Stack } from '@chakra-ui/react';
import _ from 'lodash';

export const getPlaceHolder = () => {
  return (
    <Box>
      <Stack paddingTop={5} paddingLeft={5} paddingRight={5} gap={2}>
        {_.map(new Array(6), (x, index) => {
          return <Skeleton key={`skeleton-${index}`} height="2rem" />;
        })}
      </Stack>
    </Box>
  );
};
