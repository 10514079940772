import { ChartType } from "chart.js";
import { useEffect, useState } from "react";
import { LineChartProp } from "../Line/LineChart";
import { useChart } from "./use-chart";
import { ChartOptionBuilder } from "../../../../services/chart-option-service";

export const useLineChart = (
  chartProps: LineChartProp
): [
  options: ChartOptionBuilder<ChartType>,
  baseProps: any,
  pointBorderColor: string,
  pointColor: string
] => {
  const [options, labels, dataSets] = useChart(chartProps);
  const [tension, setTension] = useState<number>();
  const [pointSize, setDotSize] = useState<number>();
  const [borderWidth, setBorderWidth] = useState<number>();
  const [pointColor, setPointColor] = useState<string>();
  const [pointBorderColor, setPointBorderColor] = useState<string>();
  const [pointBorderWidth, setPointBorderWidth] = useState<number>();

  const baseProps: any = {
    labels,
    dataSets,
  };

  const _options = options
    .setLineTension(tension ?? 0)
    .setLineBorderWith(borderWidth ?? 3)
    .setLinePointRadius(pointSize ?? 0)
    .setLinePointBorderWidth(pointBorderWidth ?? 0);

  useEffect(() => {
    if (chartProps) {
      setTension(chartProps.tension);
      setDotSize(chartProps.pointSize);
      setPointColor(chartProps.pointColor);
      setBorderWidth(chartProps.borderWidth);
      setPointBorderColor(chartProps.pointBorderColor);
      setPointBorderWidth(chartProps.pointBorderWidth);
    }
  }, [chartProps]);

  return [
    _options,
    baseProps,
    pointBorderColor || "transparent",
    pointColor || "transparent",
  ];
};
