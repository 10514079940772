export const shouldBeLoading = (
  selectedContractId: string,
  activeContractId: string,
  activateLoading: boolean,
  activeActionCoincidence?: boolean
) => {
  if (
    selectedContractId === activeContractId &&
    activateLoading &&
    activeActionCoincidence
  ) {
    return true;
  }
  return false;
};
