import {
  Flex,
  Box,
  Divider,
  Input,
  ListItem,
  UnorderedList,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { AccessTypes, IAccess } from '@mentaport/types-common';
import { useDialog } from '../../../hooks/use-dialog';
import { MessageStatus } from '../../../services/dialog';
import { AddIcon, SmallCloseIcon } from '@chakra-ui/icons';

export interface WhoSectionProps {
  customElements: string[];
  onChange: (whoValue: IAccess) => void;
}

export const WhoSection: FunctionComponent<WhoSectionProps> = props => {
  const [customElements, setCustomElements] = useState<string[]>(
    props.customElements
  );
  const [currentElement, setCurrentElement] = useState<string>('');
  const dialog = useDialog();

  const handleChange = (elements: string[]) => {
    props.onChange?.({
      type: AccessTypes.Custom,
      whitelist: elements,
    });
  };

  const removeElement = (index: number) => {
    const tempCustomElements = [...customElements];
    tempCustomElements.splice(index, 1);
    setCustomElements(tempCustomElements);
    handleChange(tempCustomElements);
  };

  const handleAddElement = () => {
    if (!currentElement) {
      dialog.notify(
        MessageStatus.Error,
        'Who section',
        'The wallet address can´t be empty'
      );
      return;
    }
    if (customElements.includes(currentElement)) {
      dialog.notify(
        MessageStatus.Info,
        'Who section',
        'This item is already in the wallet list'
      );
      return;
    }
    const tempCustomElements = [...customElements, currentElement];
    setCustomElements(tempCustomElements);
    handleChange(tempCustomElements);
    setCurrentElement('');
  };
  const getWalletInputs = () => {
    return (
      <Flex direction={'column'} gap={'1rem'}>
        <strong>Wallet List</strong>
        <Flex gap={1}>
          <Input
            onKeyDown={event => {
              if (event.code === 'Enter') {
                handleAddElement();
              }
            }}
            value={currentElement}
            bgColor={'white'}
            placeholder="Wallet address"
            onChange={event => {
              setCurrentElement(event.currentTarget.value);
            }}
          />
          <IconButton
            isDisabled={currentElement === ''}
            icon={<AddIcon />}
            onClick={() => {
              handleAddElement();
            }}
            aria-label={''}
          ></IconButton>
        </Flex>
        <Divider border={'0.5px solid gray'} orientation="horizontal" />
        <Box bgColor={'white'} borderRadius={10}>
          {customElements.length > 0 && (
            <UnorderedList
              marginTop={'1rem'}
              marginBottom={'1rem'}
              marginLeft={'1.5rem'}
            >
              {customElements?.map((element, index) => {
                return (
                  <ListItem key={index} className={`CustomElement ${element}`}>
                    <Flex align="center" justify={'space-between'} p={1}>
                      <Text w={'90%'}>{element}</Text>
                      <IconButton
                        variant={'ghost'}
                        icon={<SmallCloseIcon />}
                        onClick={() => {
                          removeElement(index);
                        }}
                        aria-label={''}
                      ></IconButton>
                    </Flex>
                  </ListItem>
                );
              })}
            </UnorderedList>
          )}
        </Box>
      </Flex>
    );
  };

  return (
    <Flex flexDirection={'column'} gap={5}>
      {getWalletInputs()}
    </Flex>
  );
};
