import {
  Box,
  Button,
  Flex,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Skeleton,
  Text,
  Link,
} from '@chakra-ui/react';
import { withAuthProtection } from '../../services/protect-route-element';
import './DashboardPage.scss';
import { useState } from 'react';
import { useApiKeys } from '../../hooks/use-api-key';
import { useNavigate } from 'react-router-dom';
import { LocalIcons } from '../../assets/icons/icons';
import { useAnalytics } from '../../hooks/use-analytics';
import { authentication } from '../../services/authentication';

const Page = () => {
  const [reveal, setReveal] = useState(false);
  const [apiKeys, isLoading] = useApiKeys();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const user = authentication.getUser();
  if (user) {
    analytics?.identify(user.sub || user.user_id || user.email, {
      email: user.email,
      name: user.name,
      username: user.nickname,
      avatar: user.picture,
    });
  }

  const renderApiKeyBox = () => {
    return (
      <Box className="MentaportCard ApiKeys">
        <Box className="Title">API KEY</Box>
        <Skeleton className="Content" isLoaded={!isLoading}>
          <Box className="Content">
            {!isLoading && apiKeys.length ? (
              <Box className="KeyEntry">
                <InputGroup size="sm">
                  <InputLeftAddon
                    children="Key value"
                    borderRadius={'5px 0 0 5px'}
                  />
                  <Input
                    pr="4.5rem"
                    borderRadius={'0 5px 5px 0'}
                    isReadOnly={true}
                    type={reveal ? 'text' : 'password'}
                    value={apiKeys[apiKeys.length - 1]}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      variant={'yellow'}
                      size={'xs'}
                      onClick={() => {
                        setReveal(!reveal);
                      }}
                    >
                      {reveal ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            ) : (
              <Button
                variant={'pink'}
                size={'xs'}
                onClick={() => {
                  navigate('/create-api-key');
                }}
              >
                Create
              </Button>
            )}
          </Box>
        </Skeleton>
      </Box>
    );
  };

  return (
    <Flex className="Dashboard" flexDirection={'column'} gap={5}>
      <Box className="Placeholder">
        <div className="intro-block">
          <Text fontFamily={'Exo'} as="b" fontSize={'4xl'}>
            Create location-aware smart contracts with a few lines of code
          </Text>
          <h2>
            Deploy smart contracts that respond to your community's location and
            update over time.
          </h2>
        </div>
        <div className="code-block">
          <code>npm install @mentaport/core</code>
        </div>
      </Box>
      <Box className="Container">
        {renderApiKeyBox()}
        <Box className="MentaportCard DocLinks">
          <Box className="Title">USEFUL LINKS</Box>
          <Box className="dashboard_link_container">
            <Link
              className="dashboard_link"
              href="https://docs.mentaport.xyz"
              isExternal
            >
              <Img cursor={'pointer'} src={LocalIcons.SDK} />
              SDK
            </Link>
            <Link
              className="dashboard_link"
              href="https://docs.mentaport.xyz/docs/Tutorials/intro_tutorial"
              isExternal
            >
              <Img cursor={'pointer'} src={LocalIcons.Tutorials}></Img>
              Tutorials
            </Link>
            <Link
              className="dashboard_link"
              href="https://github.com/mentaport"
              isExternal
            >
              <Img cursor={'pointer'} src={LocalIcons.GitHub}></Img>
              GitHub
            </Link>
          </Box>
        </Box>
        <a
          className="MentaportCard ShowCase"
          href="https://www.youtube.com/watch?v=BmaK0lM-5JA"
        >
          <Flex
            className="media_button"
            justify={'center'}
            align={'center'}
            bgColor={'#44404090'}
            w={100}
            h={100}
            borderRadius={500}
          >
            <Box
              className="media_button_image"
              backgroundImage={LocalIcons.VideoMark}
              backgroundSize={'contain'}
              backgroundRepeat={'no-repeat'}
              marginLeft={3}
              w={10}
              h={10}
            />
          </Flex>
        </a>
      </Box>
    </Flex>
  );
};

export const DashboardPage = withAuthProtection(Page);
