import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  ResponsiveValue,
} from '@chakra-ui/react';
import { FunctionComponent, ReactNode, useState } from 'react';
import {
  IAccess,
  LocationTypes,
  ITime,
  TimeTypes,
  ILocation,
} from '@mentaport/types-common';

import { WhenSection } from '../../../Pages/NewRulePage/components/WhenSection';
import { WhereSection } from '../../../Pages/NewRulePage/components/WhereSection/WhereSection';
import { WhoSection } from '../../../Pages/NewRulePage/components/WhoSections';
import { getLocationType } from '../../../helpers/mentaport-types-helper';
import { useDialog } from '../../../hooks/use-dialog';
import { MessageStatus } from '../../../services/dialog';

export interface DrawerDialogProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  size?: string;
}

export const DrawerDialog: FunctionComponent<DrawerDialogProps> = props => {
  return (
    <Drawer
      isOpen={props.isOpen}
      placement="right"
      onClose={props.onClose}
      size={props.size || 'md'}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={'brand_black.50'}>
        <DrawerCloseButton />
        <DrawerHeader>{props.title}</DrawerHeader>
        <DrawerBody>{props.children}</DrawerBody>
        <DrawerFooter justifyContent={'center'}>
          <Button
            variant="dark"
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel. I'll activate this contract later
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
