import { Button, Flex, Box, Link, Grid, GridItem } from '@chakra-ui/react';
import { withAuthProtection } from '../../services/protect-route-element';
import { useMentaport } from '../../hooks/use-mentaport';
import {
  BlockchainTypes,
  ContractEnvironment,
  IContract,
  ContractStatus,
} from '@mentaport/types-supplement';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './ManageContractPage.scss';
import { OptionList } from '../../components/commons/OptionList/OptionList';
import { MessageStatus } from '../../services/dialog';
import { useDialog } from '../../hooks/use-dialog';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { Routes } from '../../data/routes';
import { getEditOrCreate } from '../../helpers/pages-helper';
import { getPlaceHolder } from '../../helpers/list-helper';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { MentaportInput } from '../../components/commons/CreateContractInput/MentaportInput';
import {
  getDefaultBlockchainTypeOptions,
  getDefaultContractEnvironmentOptions,
  regenerateOptionsWithNewValue,
} from '../../helpers/options-helper';
import { trackEvent, useAnalytics } from '../../hooks/use-analytics';

export const Page = () => {
  const { contractId } = useParams();
  const analytics = useAnalytics();
  const dialog = useDialog();
  const mentaportService = useMentaport();
  const navigate = useNavigate();
  const [contract, setContract] = useState<IContract>({
    environment: window.location.pathname.includes('mezzanine')
      ? ContractEnvironment.Mezzanine
      : ContractEnvironment.Testnet,
    name: '',
    blockchain: window.location.pathname.includes('mezzanine')
      ? BlockchainTypes.None
      : BlockchainTypes.Polygon,
    contractId: '',
    createdAt: '',
    customerId: '',
    status: ContractStatus.NonActive,
  });
  const [isMezzanineSelected, setIsMezzanineSelected] = useState(false);

  useEffect(() => {
    if (contract.environment === ContractEnvironment.Mezzanine) {
      setIsMezzanineSelected(true);
    } else {
      setIsMezzanineSelected(false);
    }
  }, [contract.environment]);

  const [contractEnvironmentOptions, setContractEnvironmentOptions] = useState(
    getDefaultContractEnvironmentOptions(
      window.location.pathname.includes('contracts/mezzanine')
    )
  );
  const [blockchainTypeOptions, setBlockchainTypeOptions] = useState(
    getDefaultBlockchainTypeOptions(
      window.location.pathname.includes('contracts/mezzanine')
    )
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [_, isLoadingContractData] = useDataFetcher({
    serviceCall: () => {
      if (!contractId) {
        return;
      }
      return mentaportService?.getContractById(contractId);
    },
    onFinish: response => {
      if (!response?.data) {
        return;
      }
      setContract(response?.data);
      if (response?.data?.environment) {
        const newEnvironmentOptions = regenerateOptionsWithNewValue(
          response?.data?.environment,
          getDefaultContractEnvironmentOptions(
            window.location.pathname.includes('mezzanine')
          )
        );
        setContractEnvironmentOptions(newEnvironmentOptions);
      }
      if (
        response?.data?.blockchain &&
        response?.data?.environment !== ContractEnvironment.Mezzanine
      ) {
        const newBlockchainTypeOptions = regenerateOptionsWithNewValue(
          response?.data?.blockchain,
          getDefaultBlockchainTypeOptions(
            window.location.pathname.includes('contracts/mezzanine')
          )
        );
        setBlockchainTypeOptions(newBlockchainTypeOptions);
      }
    },
    onError: error => {
      dialog.notify(
        MessageStatus.Error,
        'Contract fetching',
        error.data?.message
      );
      navigate(-1);
    },
    dependencies: [mentaportService],
    conditionForExecution:
      contractId !== undefined && mentaportService !== undefined,
  });

  const handleSubmitForm = async () => {
    try {
      setIsLoading(true);

      if (!contract.blockchain) {
        dialog.notify(
          MessageStatus.Error,
          `${getEditOrCreate(!contractId)} Contract`,
          'The block chain type is required'
        );
        return;
      }

      if (
        !contract.owner &&
        contract.environment !== ContractEnvironment.Mezzanine
      ) {
        dialog.notify(
          MessageStatus.Error,
          `${getEditOrCreate(!contractId)} Contract`,
          'The wallet owner is required'
        );
        return;
      }

      if (
        contract.blockchain === BlockchainTypes.None &&
        contract.environment !== ContractEnvironment.Mezzanine
      ) {
        dialog.notify(
          MessageStatus.Error,
          `${getEditOrCreate(!contractId)} Contract`,
          'You must select a blockchain'
        );
        return;
      }

      if (contractId) {
        if (analytics) {
          trackEvent(
            `${getEditOrCreate(
              false
            )} ${contract.environment.toString()} Contract`,
            analytics
          );
        }
        const _updatedContract = await mentaportService?.updateContract(
          contractId,
          {
            name: contract.name,
            blockchain:
              contract.environment === ContractEnvironment.Mezzanine
                ? BlockchainTypes.None
                : contract.blockchain,
            environment: contract.environment,
            owner: contract.owner,
            cost: contract.cost ? contract.cost : 0,
            maxSupply: contract.maxSupply ? contract.maxSupply : 1000000,
            maxMintAmount: contract.maxMintAmount
              ? contract.maxMintAmount
              : 1000,
            projectBaseURI: contract.projectBaseURI,
            updatedAt: new Date().toString(),
          }
        );
        navigate(-1);
      } else {
        if (analytics) {
          trackEvent(
            `${getEditOrCreate(
              true
            )} ${contract.environment.toString()} Contract`,
            analytics
          );
        }
        const newContract = await mentaportService?.createNewContract({
          blockchain:
            contract.environment === ContractEnvironment.Mezzanine
              ? BlockchainTypes.None
              : contract.blockchain,
          environment: contract.environment,
          name: contract.name,
          owner: contract.owner ?? 'N/A',
          cost: contract.cost ? contract.cost : 0,
          maxSupply: contract.maxSupply ? contract.maxSupply : 1000000,
          maxMintAmount: contract.maxMintAmount ? contract.maxMintAmount : 1000,
          projectBaseURI: contract.projectBaseURI,
        });
        const contractsRoot =
          contract.environment === 'mezzanine' ? 'mezzanine' : 'blockchain';
        navigate(
          `/contracts/${contractsRoot}/${newContract?.contractId}/${newContract?.name}/rules/new`
        );
      }
      dialog.notify(
        MessageStatus.Success,
        `${getEditOrCreate(!contractId)} Contract`,
        'Your contract is ready.'
      );
    } catch (error: any) {
      dialog.notify(
        MessageStatus.Error,
        `${getEditOrCreate(!contractId)} Contract`,
        error?.data?.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isContractDataBeingFetched = (): boolean => {
    return contractId !== undefined && isLoadingContractData;
  };

  const isFormReadyForSubmission = () => {
    if (contract.environment === ContractEnvironment.Mezzanine) {
      return !contract.name || isContractDataBeingFetched();
    } else {
      return (
        !contract.name ||
        !contract.owner ||
        contract.blockchain === BlockchainTypes.None ||
        isContractDataBeingFetched()
      );
    }
  };

  const renderBlockchainSection = () => {
    return (
      <>
        <Box className="Divider"></Box>
        <Box className="NewContractPageOptionList" w={'100%'}>
          <OptionList
            sectionTitle="Blockchain"
            options={blockchainTypeOptions}
            isLoading={isLoading || isContractDataBeingFetched()}
            disabled={isMezzanineSelected}
            onSelectValue={option =>
              setContract({
                ...contract,
                blockchain: option.value as BlockchainTypes,
              })
            }
          />
        </Box>
        <MentaportInput
          isDisabled={isContractDataBeingFetched()}
          title="Owner's wallet address"
          value={isMezzanineSelected ? '' : contract.owner ?? ''}
          onChange={value => {
            setContract({ ...contract, owner: value });
          }}
        />

        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(3, 1fr)',
          ]}
          gap={4}
          p={0}
        >
          <GridItem>
            <MentaportInput
              inputType={'number'}
              isDisabled={isContractDataBeingFetched()}
              title="Price"
              subtitle="(Eth, Matic, Sui)"
              minNumericValue={0}
              value={contract.cost ?? 0}
              onChange={value => {
                setContract({ ...contract, cost: value });
              }}
            />
          </GridItem>
          <GridItem>
            <MentaportInput
              inputType={'number'}
              isDisabled={isContractDataBeingFetched()}
              title="Total Supply"
              maxNumericValue={1000}
              minNumericValue={1}
              value={contract.maxSupply ?? 10000}
              onChange={value => {
                setContract({ ...contract, maxSupply: value });
              }}
            />
          </GridItem>
          <GridItem>
            <MentaportInput
              inputType={'number'}
              isDisabled={isContractDataBeingFetched()}
              title="Max per wallet"
              maxNumericValue={1000}
              minNumericValue={1}
              value={contract.maxMintAmount ?? 100}
              onChange={value => {
                setContract({ ...contract, maxMintAmount: value });
              }}
            />
          </GridItem>
        </Grid>
      </>
    );
  };

  const renderCreateContract = () => {
    return (
      <Flex className="NewContractPage" alignItems={'center'}>
        <Box className="Container Left">
          <MentaportInput
            isDisabled={isContractDataBeingFetched()}
            title="Contract Name"
            value={contract.name}
            onChange={value => {
              setContract({ ...contract, name: value });
            }}
          />
          <MentaportInput
            isDisabled={isContractDataBeingFetched()}
            title="Project Public URL (optional)"
            value={contract.projectBaseURI ?? ''}
            onChange={value => {
              setContract({ ...contract, projectBaseURI: value });
            }}
          />

          <Box className="NewContractPageOptionList" w={'100%'}>
            <OptionList
              sectionTitle="Contract Environment"
              options={contractEnvironmentOptions}
              disabled={contractId !== undefined}
              isLoading={isLoading || isContractDataBeingFetched()}
              onSelectValue={option => {
                const environment = option.value as ContractEnvironment;
                setContract({
                  ...contract,
                  environment: environment,
                  blockchain:
                    environment === ContractEnvironment.Mezzanine
                      ? BlockchainTypes.None
                      : contract.blockchain === BlockchainTypes.None
                      ? (blockchainTypeOptions.find(x => x.isSelected === true)
                          ?.value as BlockchainTypes)
                      : contract.blockchain,
                });
              }}
            />
          </Box>
          {!isMezzanineSelected && renderBlockchainSection()}
          <Box className="Divider"></Box>
          <Box className="ActionsSection">
            <Button
              className="CreateContract"
              isDisabled={isFormReadyForSubmission()}
              variant={'dark'}
              onClick={() => {
                handleSubmitForm();
              }}
            >
              Save Contract
            </Button>
            <Button
              className="Cancel"
              variant={'dark'}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>

        <Box className="Container Right">
          <Box className="TextSection">
            <Box className="Title">Contract</Box>
            <Box className="Definition">
              A contract is our main unit of work. They can be deployed in the
              blockchain or in the Mentaport development platform.
            </Box>
          </Box>

          <Box className="ActionsSection">
            <Button className="GoToDocs" variant={'pink'}>
              <Link
                href="https://docs.mentaport.xyz/docs/Dashboard/contracts"
                isExternal
              >
                Go to Docs
              </Link>
            </Button>
          </Box>
        </Box>
      </Flex>
    );
  };

  const buildTitle = () => {
    let contractNameStr = contract.name ?? '';
    if (contractNameStr !== '') {
      contractNameStr = "'" + contractNameStr + "'";
    }
    const suffix =
      contract.name.endsWith('contract') || contract.name.endsWith('Contract')
        ? ''
        : 'contract';
    return `${getEditOrCreate(!contractId)} my ${contractNameStr} ${suffix}`;
  };

  return (
    <ManagementPage
      title={buildTitle()}
      path={''}
      hideBackButton={true}
      isLoading={false}
      previousLocation={Routes.Contracts}
      placeholder={getPlaceHolder()}
    >
      {renderCreateContract()}
    </ManagementPage>
  );
};
export const ManageContractPage = withAuthProtection(Page);
