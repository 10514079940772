import { MentaportService } from '../services/mentaport-service';
import { useState, useEffect } from 'react';
import { useApiKeys } from './use-api-key';
import { getClient } from '../helpers/dashboard-helper';

export function useMentaport() {
  const [mentaport, setMentaport] = useState<MentaportService>();
  const [apiKeys, isApiKeyLoading] = useApiKeys();
  useEffect(() => {
    if (isApiKeyLoading) {
      return;
    }
    const client = getClient(apiKeys);
    if (!client) {
      return;
    }
    const loadMentaport = async () => {
      setMentaport(new MentaportService(client));
    };
    loadMentaport();
  }, [apiKeys, isApiKeyLoading]);

  return mentaport;
}
