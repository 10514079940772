import { CopyIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { MetricElement } from "../data/metric-element";
import DiamondIcon from "@mui/icons-material/Diamond";

const blackColor = "#1e1c1f";

export interface MetricsCardProps {
  metrics: MetricElement[];
}

export const MetricsCard: FunctionComponent<MetricsCardProps> = (props) => {
  const [metrics, setMetrics] = useState<MetricElement[]>(props.metrics);

  useEffect(() => {
    if (!props) {
      return;
    }
    setMetrics(props.metrics);
  }, [props]);

  return (
    <Card
      variant={"elevation"}
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: blackColor,
        borderRadius: "10px",
        height: "100%",
      }}
    >
      <CardContent>
        <Box display={"flex"} gap={20} alignItems={"center"}>
          <Box>
            <IconButton
              aria-label="Copy code"
              colorScheme="pink"
              icon={<CopyIcon />}
            />
          </Box>
          {metrics?.map((element) => {
            return (
              <Box display={"flex"} flexDirection={"column"}>
                <Box display={"flex"} alignItems={"center"}>
                  <DiamondIcon color={"secondary"} />
                  <Typography variant={"h5"} color={"white"}>
                    {element.value}
                  </Typography>
                </Box>
                <Typography variant={"caption"} color={"white"}>
                  {element.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};
