import {
  Flex,
  Box,
  Divider,
  Input,
  ListItem,
  UnorderedList,
  IconButton,
  Text,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { ICoordinate } from '@mentaport/types-common';
import { AddIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { MessageStatus } from '../../../../../services/dialog';
import { useDialog } from '../../../../../hooks/use-dialog';

export interface PolygonElementProps {
  polygons: ICoordinate[];
  onChange: (value: ICoordinate[]) => void;
}

export const PolygonElement: FunctionComponent<PolygonElementProps> = props => {
  const dialog = useDialog();
  const [polygons, setPolygons] = useState<ICoordinate[]>(props.polygons);
  const [currentCoordinate, setCurrentCoordinate] = useState<ICoordinate>({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    props.onChange(polygons);
  }, [polygons]);

  const removeElement = (index: number) => {
    const tempPolygons = [...polygons];
    tempPolygons.splice(index, 1);
    setPolygons(tempPolygons);
  };

  const handleAddElement = () => {
    if (!currentCoordinate) {
      dialog.notify(
        MessageStatus.Error,
        'Polygon section',
        'The polygon coordinate can´t be empty'
      );
      return;
    }
    if (polygons.includes(currentCoordinate)) {
      dialog.notify(
        MessageStatus.Info,
        'Polygon section',
        'This item is already in the wallet list'
      );
      return;
    }
    const tempPolygons = [...polygons, currentCoordinate];
    setPolygons(tempPolygons);
    setCurrentCoordinate({ latitude: 0, longitude: 0 });
  };
  const getWalletInputs = () => {
    return (
      <Flex direction={'column'} gap={'1rem'}>
        <strong>Polygon List</strong>
        <Box className="LatitudeEntry">
          <InputGroup size="md">
            <InputLeftAddon width={'6rem'} children="Latitude" />
            <Input
              value={
                currentCoordinate?.latitude !== 0
                  ? currentCoordinate?.latitude
                  : ''
              }
              onChange={event => {
                if (!currentCoordinate) {
                  return;
                }
                setCurrentCoordinate({
                  ...currentCoordinate,
                  latitude: +event.currentTarget.value,
                });
              }}
              bgColor={'white'}
              type={'number'}
            />
          </InputGroup>
        </Box>
        <Box className="LongitudeEntry">
          <InputGroup size="md">
            <InputLeftAddon width={'6rem'} children="Longitude" />
            <Input
              value={
                currentCoordinate?.longitude !== 0
                  ? currentCoordinate?.longitude
                  : ''
              }
              onChange={event => {
                if (!currentCoordinate) {
                  return;
                }
                setCurrentCoordinate({
                  ...currentCoordinate,
                  longitude: +event.currentTarget.value,
                });
              }}
              bgColor={'white'}
              type={'number'}
            />
          </InputGroup>
        </Box>

        <IconButton
          isDisabled={
            currentCoordinate.latitude === 0 ||
            currentCoordinate.longitude === 0
          }
          icon={<AddIcon />}
          onClick={() => {
            handleAddElement();
          }}
          aria-label={''}
        ></IconButton>
        <Divider border={'0.5px solid gray'} orientation="horizontal" />
        <Box bgColor={'white'} borderRadius={10}>
          {polygons.length > 0 && (
            <UnorderedList
              marginTop={'1rem'}
              marginBottom={'1rem'}
              marginLeft={'1.5rem'}
            >
              {polygons?.map((polygon, index) => {
                return (
                  <ListItem key={index} className={`CustomElement ${polygon}`}>
                    <Flex align="center" justify={'space-between'} p={1}>
                      <Text
                        w={'90%'}
                      >{`Lat: ${polygon.latitude} | Long: ${polygon.longitude}`}</Text>
                      <IconButton
                        variant={'ghost'}
                        icon={<SmallCloseIcon />}
                        onClick={() => {
                          removeElement(index);
                        }}
                        aria-label={''}
                      ></IconButton>
                    </Flex>
                  </ListItem>
                );
              })}
            </UnorderedList>
          )}
        </Box>
      </Flex>
    );
  };

  return (
    <Flex flexDirection={'column'} gap={5}>
      {getWalletInputs()}
    </Flex>
  );
};
