import { ActiveElement, Chart, ChartEvent, TooltipOptions } from "chart.js";

export interface BaseChartProp {
  axisStyle: AxisStyle[];
  dataLabelStyle?: DataLabelStyle;
  showLegend?: boolean;
  title?: string;
  responsive: boolean;
  maintainAspectRatio?: boolean;
  height?: number;
  width?: number;
  aspectRatio?: number;
  chartData: ChartData;
  customLegend?: boolean;
  showZeroForEmptyLabel?: boolean;
  clip?: number | false | ChartClip;
  customToolTip?: TooltipOptions;
  padding?: number;
  onClick?: (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: Chart
  ) => void;
  onHover?: (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: Chart
  ) => void;
  zoomOnHover?: ZoomHoverProps;
}

export interface DataLabelStyle {
  display: boolean;
  color?: string;
  fontSize?: number;
  align?: DataLabelPosition;
  anchor?: DataLabelPosition;
  padding?: number;
  onFormatter?: (value: any, context: any) => void;
}

export interface ZoomHoverProps {
  hoverBorderWidth: number;
  hoverBorderJoinStyle: string;
}

export interface AxisStyle {
  id: string;
  gridColor?: string;
  gridBorderColor?: string;
  showScalesGrid?: boolean;
  tickStepSize?: number;
  tickBackgroundColor?: string;
  tickColor?: string;
  tickFontSize?: number;
  tickFontWeight?: number;
  isLogarithmicScale?: boolean;
  grace?: string;
  max?: number;
  min?: number;
  showScales?: boolean;
  position?: string;
  showScalesGridBorder?: boolean;
}

export interface ChartData {
  x: any[];
  dataSets: DataSet[];
}

export interface DataSet {
  type?: ChartType;
  label?: string;
  yAxisID?: string;
  data: any[];
  borderColor: string;
  borderWidth?: number;
  borderRadius?: number;
  backgroundColor: string | string[];
  gridBorderColor?: string;
}

export interface ChartClip {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export enum ChartType {
  Line = "Line",
  Bar = "Bar",
}

export enum DataLabelPosition {
  End = "end",
  Start = "start",
}

export const EmptyState = "empty";
